<!-- based on iam_vishalkhare-chatter-box -->

<div class="vis-chat-window">
    <div class="vis-chat-window-header">

    <!-- <img *ngIf="headerImage !== null && headerImage !== undefined"
                            [src]="headerImage"
                            alt="user"
                            class="user-image"/> -->

        <div class="vis-chat-window-header-text">
            Comments <!--{{heading}}-->
        </div>
    </div>
    <div class="vis-chat-window-body" #scrollMe [scrollTop]="scrollMe.scrollHeight">
        <div class="error-div" *ngIf="showErrorDiv">
            {{errorMsg}}
        </div>
        <div *ngIf="!showErrorDiv">
            <div *ngFor="let comment of allComments">
              <div [ngClass]="(comment.userId !== null && comment.userId !== undefined) ? ((comment.userId == currentUserId) ? 'comment-currentUser' : 'comment') :'comment'">

                <!-- <div class="col-2">
                    <img *ngIf="!showImageOrDiv(comment)"
                            src="{{comment.userImagePath}}"
                            alt="user"
                            class="user-image"
                    />
                    <div *ngIf="showImageOrDiv(comment)" class="user-image color-gray">
                      {{comment.userName[0]}}
                    </div>
                </div> -->

                <div class="comment-bubble col-10" [ngClass]="(comment.userId !== null && comment.userId !== undefined) ? ((comment.userId == currentUserId) ? 'comment-bubble-currentUser col-10' : 'comment-bubble col-10') :'comment-bubble col-10'">
                    <div [ngClass]="(comment.userId !== null && comment.userId !== undefined) ? ((comment.userId == currentUserId) ? 'user-name' : 'user-name color-gray') :'user-name color-gray'">
                        {{comment.userName}}
                    </div>
                    <div [ngClass]="(comment.userId !== null && comment.userId !== undefined) ? ((comment.userId == currentUserId) ? 'user-comment' : 'user-comment color-gray') :'user-comment color-gray'">
                        {{comment.msg}}
                    </div>
                    <div [ngClass]="(comment.userId !== null && comment.userId !== undefined) ? ((comment.userId == currentUserId) ? 'timestamp' : 'timestamp color-gray') :'timestamp color-gray'">
                        {{comment.timestamp}} 
                        <button *ngIf="comment.userId == currentUserId" (click)="onDeleteButton(comment)">delete</button>
                        <button *ngIf="comment.userId != currentUserId"(click)="onReportButton(comment)">report</button>
                    </div>
                    
                </div>
              </div>
            </div>
        </div>
    </div>
    <div class="vis-chat-window-footer">
        <div>
          <textarea *ngIf="currentUserId" id="vis-comment-textField" placeholder="{{placeholderText}}"
                [(ngModel)]="commentText"
                (keyup)="onKeyUp(commentText)"
                maxlength="{{inputMaxLength}}"></textarea>
          <button *ngIf="currentUserId" type="button" (click)="addComment(commentText)">send</button> 
          <button *ngIf="!socketIo" style="float: right; margin-right: 16px;" (click)="onRefreshButton()">refresh</button>
        </div>
        <div *ngIf="currentUserId" class="character-count">
            {{typedCommentTextLength}} / {{inputMaxLength}} characters
        </div>
        
    </div>
  </div>