<div *ngIf="artifacts" fxLayout="column">
  <gd-header *ngIf="artifacts" title='Artifacts'
    [subtitle]='types' imageUrl="{{ artifacts[0] }}" userId='{{ currentUser.userId }}'>
  </gd-header>
  <div *ngIf="artifacts.length" class="gd-container" fxLayout="row wrap" fxLayout.xs="column">
    <div *ngFor="let a of artifacts" class="gd-container" fxLayout="column" fxFlex="33" fxFlex.xs="100">
      <img class="image" [src]="a.thumbnail || a.image" [style.object-fit]="'cover'" [style.width]="'100%'">
      {{a.description}}<br>
      {{a.eventInfo}}<br>
      {{a.type}} in {{a.collection}} collection
    </div>
  </div>
</div>