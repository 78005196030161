<gd-header title='{{ "About" }}'
    subtitle='{{ "" }}' userId='{{ currentUser.userId }}' menu='"off"'>
  </gd-header>

<meta charset="UTF-8">
<meta name="viewport" content="width=device-width, initial-scale=1">
<link rel="stylesheet" href="https://www.w3schools.com/w3css/4/w3.css">
<link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Lato">
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
<style>
div {font-family: "Lato", sans-serif; color:rgb(59, 59, 59)}
a.disabled {
  pointer-events: none;
  cursor: default;
}
hr.rounded {
  border-top: 2px solid rgb(131, 131, 131);
  border-radius: 1px;
}
#note {
        position: relative;
    }
    #note img.close {
        position: absolute;
        top: 1px;
        right: 1px;
        cursor: pointer;
    }
</style>


<!-- Page content -->
  
<div class="w3-content" style="max-width:2000px;margin-top:32px">
  
  <div *ngIf="showNote" class="w3-container w3-content w3-center" style="max-width:800px">
    <p class="w3-justify" style="background-color: rgb(255, 253, 129); padding: 10px; border-radius: 10px;" id="note">
      <img class="close" src="/assets/preview/close.png" height="12px" (click)="closeNote()">
      <img src="/assets/preview/warning-32.gif" height="16px"> Please note that this website is currently in its experimental stages. The designs have not been implemented yet and it is not yet optimized for mobile devices. You can see a preview of what we envision the site to look like here: <u><a href="/assets/preview/ConcertMap4.png" target="_blank">1</a></u> <u style="padding-left: 6px;"><a href="/assets/preview/VenueView1.png" target="_blank">2</a></u> <u style="padding-left: 6px;"><a href="/assets/preview/ShowView.png" target="_blank">3</a></u>. We are also planning more functionality based on <a routerLink="." fragment="technology"><u>audio analysis</u></a>. 
      <br>
      While the development was initially supported by Queen Mary University of London, we are now working on it in our own time. If you want to support us you can donate <u style="cursor:pointer;" (click)="buyMeACoffee()">here</u>.
  </p>
</div>

  <!-- The Band Section -->
  <div class="w3-container w3-content w3-center w3-padding-32" style="max-width:800px;" id="top">
    <!-- <h2 class="w3-wide" [routerLink]="['/mapselect']">THE GRATEFUL DEAD CONCERT EXPLORER</h2> -->
    <div style="cursor:pointer"><img src="/assets/gd_logo1_start.png" alt="grateful dead explorer logo" style="width:65%" [routerLink]="['/mapselect']"></div>
    <p class="w3-opacity" style="font-size: small;"><u style="cursor:pointer" routerLink="/mapselect">click to enter</u></p>
      </div>


      
  <div class="w3-container w3-content w3-center" style="max-width:800px" style="padding-bottom: 32px;" id="overviewstart">
      <p class="w3-center">
          The Grateful Dead Concert Explorer is a Web application that enables user to navigate and explore digital libraries of live music recordings and discover and share associated artifacts that are dynamically linked within the application. 
      </p>

      <div class="w3-container w3-content w3-center w3-padding-16" style="max-width:800px" id="logostart">
        <a href="https://archive.org" target="_blank"> <img src="https://archive.org/images/glogo.png" class="w3-round w3-margin-right" alt="internet archive logo" style="width:8%;"></a><span style="padding-right: 10%;"></span>
        <a href="https://www.gdao.org" target="_blank"> <img src="https://www.gdao.org/themes/gdao-theme/images/logo-gdao.png" class="w3-round w3-margin-left" alt="gdao logo" style="width:8%"></a>
    </div>

      
      <p class="w3-center">
        Archival resources in this web app have been generously provided by the The Internet Archive and the Grateful Dead Online Archive.
      </p>

      

      <img class="image w3-round w3-margin-bottom" lazyLoad="/assets/preview/home_artifacts.jpg" style="width:50%">

      <p class="w3-center">
        This web application is developed to demonstrate how Semantic Audio and Linked Data technologies can produce an improved user experience for browsing and exploring live music collections, by aggregating information from a variety of different Web sources and employing Music Information Retrieval (MIR) techniques. 
      </p>

      <p class="w3-center" style="padding-top: 10px; color:rgb(131, 131, 131)">
        ↓ learn about the explorer's functions ↓
      </p>
    </div>
      <hr class="rounded">




  <div class="w3-container w3-content w3-center w3-padding-32" style="max-width:800px" id="mapstart">
      <h3 class="w3-wide">Map View</h3>
      <img class="image w3-round w3-margin-bottom" lazyLoad="/assets/screens/scr_map.png" alt="map view" style="width:90%">
      <p class="w3-justify">
        The <i>Map View</i> shows the world map with markers indicating the locations of concerts. Clicking on a marker opens a menu with links to the pages for the venue (<a routerLink="." fragment="venuestart"><u><i>Venue View</i></u></a>), and to the pages for the concerts at that location (<a routerLink="." fragment="showstart"><u><i>Show View</i></u></a>). While by default all concert locations are displayed, specific tours can be selected from the pull-down menu on the top left. When selecting a tour, only the concerts from the tour are displayed on the map and connected by arrows indicating the order of concerts. The map also has a separate search function covering the concerts on the map.
      </p>
  </div>

  <div class="w3-container w3-content w3-center" style="max-width:800px" id="showstart">
      <h3 class="w3-wide">Show View</h3>
      <img class="image w3-round w3-margin-bottom" lazyLoad="/assets/screens/scr_show.png" alt="show view" style="width:90%">
      <p class="w3-justify">
        The <i>Show View</i>&nbsp; shows information about a specific concert, including the location, venue, the setlist, the lineup and the additional recordings available in the archive. The button next to the recording IDs opens a dialogue to either go to the <a routerLink="." fragment="recordingstart"><u><i>Recording View</i></u></a>&nbsp; for the selected recording, or add all songs of the concert recording to the playlist of the <a routerLink="." fragment="playerstart"><u>audio player</u></a>. One can navigate to other pages by clicking on any item, for instance, clicking on the venue opens the <a routerLink="." fragment="venuestart"><u><i>Venue View</i></u></a>&nbsp; for the respective venue. Clicking on the button next to a song opens a dialogue to either go to the <a routerLink="." fragment="songstart"><u><i>Song View</i></u></a>&nbsp; or add the song to the playlist of the <a routerLink="." fragment="playerstart"><u>audio player</u></a>. Clicking on photos or other artifacts either opens a higher resolution image or opens the source page at the <a href="https://www.gdao.org/" target="_blank"><u>Grateful Dead Archive Online (GDAO)</u></a> in a new tab.
      </p>
  </div>

  <div class="w3-container w3-content w3-center w3-padding-32" style="max-width:800px" id="songstart">
      <h3 class="w3-wide">Song View</h3>
      <img class="image w3-round w3-margin-bottom" lazyLoad="/assets/screens/scr_song.png" alt="song view" style="width:90%">
      <p class="w3-justify">
        The <i>Song View</i> shows information about a selected song. This includes the composers at the top of the page and the number of performances and recordings available. Clicking on an item in the list of shows opens the <a routerLink="." fragment="showstart"><u><i>Show View</i></u></a>. The button next to the location opens a dialogue which additionally lets the user add the song from the respective show to the playlist of the <a routerLink="." fragment="playerstart"><u>audio player</u></a>. The dialogue shows all available recordings from the selected concert.
      </p>
  </div>

  <div class="w3-container w3-content w3-center" style="max-width:800px" id="locationstart">
      <h3 class="w3-wide">Location View</h3>
      <img class="image w3-round w3-margin-bottom" lazyLoad="/assets/screens/scr_location.png" alt="location view" style="width:90%">
      <p class="w3-justify">
        The <i>Location View</i> shows information about a city where concerts were held. The list of shows provides links to the <a routerLink="." fragment="showstart"><u><i>Show View</i></u></a>&nbsp; for all shows at that location.
      </p>
  </div>

  <div class="w3-container w3-content w3-center w3-padding-32" style="max-width:800px" id="venuestart">
      <h3 class="w3-wide">Venue View</h3>
      <img class="image w3-round w3-margin-bottom" lazyLoad="/assets/screens/scr_venue.png" alt="venue view" style="width:90%">
      <p class="w3-justify">
          The <i>Venue View</i> shows information about a venue where concerts were held. The list of shows provides links to the <a routerLink="." fragment="showstart"><u><i>Show View</i></u></a>&nbsp; for all shows at that venue.
      </p>
  </div>

  <div class="w3-container w3-content w3-center" style="max-width:800px" id="recordingstart">
      <h3 class="w3-wide">Recording View</h3>
      <img class="image w3-round w3-margin-bottom" lazyLoad="/assets/screens/scr_recording.png" alt="recording view" style="width:90%">
      <p class="w3-justify">
        The <i>Recording View</i> shows information about a specific recording from the <a href="https://archive.org/details/etree" target="_blank"><u>Live Music Archive</u></a> at the <a href="https://archive.org/" target="_blank"><u>Internet Archive</u></a>. The information includes source and lineage information of the recording, as well as the list of songs. Clicking on the button next to a song opens a dialogue to add the selected song to the playlist of the <a routerLink="." fragment="playerstart"><u>audio player</u></a>. Clicking on the logo under the track list opens the page for the recording at the <a href="https://archive.org/" target="_blank"><u>Internet Archive</u></a> in a new tab.
      </p>
  </div>

  <div class="w3-container w3-content w3-center w3-padding-32" style="max-width:800px" id="playerstart">
      <h3 class="w3-wide">Audio Player</h3>
      <img class="image w3-round w3-margin-bottom" lazyLoad="/assets/screens/scr_player.png" alt="audio player" style="width:90%">
      <p class="w3-justify">
        The audio player at the bottom of the page streams audio directly from the <a href="https://archive.org/details/etree" target="_blank"><u>Live Music Archive</u></a>. Songs can be added to the playlist from the Web application where songs are listed, for instance in the <a routerLink="." fragment="showstart"><u><i>Show View</i></u></a>, <a routerLink="." fragment="recordingstart"><u><i>Recording View</i></u></a>&nbsp; or <a routerLink="." fragment="songstart"><u><i>Song View</i></u></a>. In addition to the standard functions (play, stop, volume, etc.), the button with the eye symbol expands/hides the waveform view and playlist.
      </p>
  </div>

  <hr class="rounded">
  <div class="w3-container w3-content w3-center w3-padding-32" style="max-width:800px">
    <h3 class="w3-wide">Why the Grateful Dead?</h3>
    <p class="w3-justify">
      First of all, because we think they are great! But this prototype demonstrator also focuses on the concerts of the Grateful Dead because of the ongoing interest in detailed descriptions of their performances, evidenced by the large amount of information available on the Web detailing various aspects of those events. The application links the large number of concert recordings available in the Internet Archive with audio analysis data and retrieves additional information and artifacts (e.g. band lineup, photos, scans of tickets and posters, reviews) from existing Web sources, to explore and visualize the collection. The project demonstrates mechanisms for musicians and the music industry to provide services for consumers to enjoy live music performances recognizing the social and cultural value of live music and fan communities.
    </p>
  </div>

  <div class="w3-container w3-content w3-center w3-padding-32" style="max-width:800px" id="technology">
    <h3 class="w3-wide">Which Technologies are you using?</h3>
    <p class="w3-justify">
      The technologies we employ in this work that were developed in the <a href="http://www.semanticaudio.ac.uk/" target="_blank"><u>EPSRC FAST IMPACt</u></a> project include:
    </p>
    <ul class="w3-justify">
        <li>Ontological data models for the representation of performed music as cultural artifacts, and its linkage to associated non-musical artifacts</li>
        <li>Music information retrieval and processing algorithms for the automatic generation of playlists and audio mixes based on user-selected criteria</li>
        <li>Music-structure analysis for the comparison of performances</li>
        <li>Audio alignment of different user-generated recordings of live-music performances</li>
    </ul>
  
      <p class="w3-justify">
        Not all functions of the demo application are listed here, and there are more functions planned to be implemented in the near future including social media aspects and MIR, e.g. user discussions of concerts and the automatic generation of playlist mixes based on user-defined parameters and audio feature analysis results. 
      </p>
        <p class="w3-justify">
          <s>A demo of a an automated mix playing excerpts of recordings of a specified song spanning the career of the Grateful Dead in a seamless mix while retrieving and displaying artifacts in real-time can be found <a class="disabled" href="https://grateful-dead-live.github.io/plunderphonics/" target="_blank"><u><b>here</b></u></a>.</s> &nbsp; <b><i>down for maintenance</i></b>
      </p>
      <p class="w3-justify">
        Here is a demo video of a an automated mix playing excerpts of recordings of a specified song spanning the career of the Grateful Dead in a seamless mix while retrieving and displaying artifacts in real-time:
      </p>
      <div style="position:relative;padding-top:56.25%;">
        <iframe src="https://www.youtube.com/embed/M-zvJYRhnL8" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;"></iframe>
      </div>
  </div>

  <hr class="rounded">

  <div class="w3-container w3-content w3-center w3-padding-16" style="max-width:800px" id="logostart">
      <a href="https://archive.org" target="_blank"> <img src="https://archive.org/images/glogo.png" class="w3-round w3-margin-right" alt="internet archive logo" style="width:8%"></a>
      <a href="http://c4dm.eecs.qmul.ac.uk" target="_blank"> <img src="https://sites.google.com/site/mathemusicalconversations/_/rsrc/1396930666900/about/c4dm_qml-logo.jpg" class="w3-round w3-margin-right w3-margin-left" alt="c4dm qmul logo" style="width:18%"></a>
      <a href="https://epsrc.ukri.org/" target="_blank"> <img src="/assets/epsrc.jpg" class="w3-round w3-margin-right w3-margin-left" alt="epsrc logo" style="width:17%"></a>
      <a href="https://www.gdao.org" target="_blank"> <img src="https://www.gdao.org/themes/gdao-theme/images/logo-gdao.png" class="w3-round w3-margin-left" alt="gdao logo" style="width:8%"></a>
  </div>
  
  <div class="w3-container w3-content w3-center" style="max-width:800px" id="emailstart">
      <!--a href="{{ 'mai' + 'lto' + ':' + part1 + part2 + part3 + part4 + part5 + part6 + part7 }}"><u>Email us</u> </a-->
      <a href="{{ 'mai' + 'lto' + ':' + email }}" target="_blank"><u>Email us</u> </a>
  </div>
  
  <p style="padding: 0 0 5em 0"></p>
<!-- End Page Content -->
</div>

