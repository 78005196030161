<div mat-dialog-content>
  <p>Save playlist</p>
  <mat-form-field>
    <mat-label>Enter name</mat-label>
    <input matInput [(ngModel)]="data.input" maxlength="50">
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onCancelClick()">Cancel</button>
  <!--button mat-button [mat-dialog-close]="data.input" cdkFocusInitial>Ok</button-->
  <button mat-button [disabled]="data.input.length == 0" (click)="onOkClick(data.input)">Ok</button> <!--cdkFocusInitial-->
</div>


<!--div mat-dialog-content>
  <p class="mat-dialog-title" >{{data.title}}</p>
  <input matInput [(ngModel)]="data.input">
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onCancelClick()">Cancel</button>
  <button mat-button [mat-dialog-close]="data" cdkFocusInitial>Ok</button>
</div-->