
<style>
  [data-custom-class='body'],
  [data-custom-class='body'] * {
    background: transparent !important;
  }

  [data-custom-class='title'],
  [data-custom-class='title'] * {
    font-family: Arial !important;
    font-size: 26px !important;
    color: #000000 !important;
  }

  [data-custom-class='subtitle'],
  [data-custom-class='subtitle'] * {
    font-family: Arial !important;
    color: #595959 !important;
    font-size: 14px !important;
  }

  [data-custom-class='heading_1'],
  [data-custom-class='heading_1'] * {
    font-family: Arial !important;
    font-size: 19px !important;
    color: #000000 !important;
  }

  [data-custom-class='heading_2'],
  [data-custom-class='heading_2'] * {
    font-family: Arial !important;
    font-size: 17px !important;
    color: #000000 !important;
  }

  [data-custom-class='body_text'],
  [data-custom-class='body_text'] * {
    color: #595959 !important;
    font-size: 14px !important;
    font-family: Arial !important;
  }

  [data-custom-class='link'],
  [data-custom-class='link'] * {
    color: #3030F1 !important;
    font-size: 14px !important;
    font-family: Arial !important;
    word-break: break-word !important;
    text-decoration: underline;
  }

</style>

<div>
 <gd-header title="Privacy Policy" subtitle="" userId='{{ currentUser.userId }}'>
</gd-header>
    <br>
    <div style="margin-right: 75px; margin-left: 75px; margin-top: 40px;">
    <div>
        <button onclick="gaOptIn()">Agree</button>
    <button onclick="gaOptOut()">Revoke</button>
    </div>
    <!--button (click)="agreeToShare()">Agree</button-->
    <!--button (click)="removeCookie()">Revoke</button-->
    <br>
    
    
    <div data-custom-class="body">
        <div>
          <strong><span style="font-size: 26px;"><span data-custom-class="title">PRIVACY
          NOTICE</span></span></strong>
        </div>
    
        <div>
          <br />
        </div>
    
        <div>
          <span style="color: rgb(127, 127, 127);"><strong><span style=
          "font-size: 15px;"><span data-custom-class="subtitle">Last updated <span class=
          "question">September 23, 2020</span></span></span></strong></span>
        </div>

          <br />
          <a href="http://c4dm.eecs.qmul.ac.uk" target="_blank"> <img src="https://sites.google.com/site/mathemusicalconversations/_/rsrc/1396930666900/about/c4dm_qml-logo.jpg" style="width:20%"></a>
     
    
        <div>
          <br />
        </div>
    
        <div style="line-height: 1.5;">
          <span style="color: rgb(127, 127, 127);"><span style=
          "color: rgb(89, 89, 89); font-size: 15px;"><span data-custom-class=
          "body_text">Thank you for choosing to be part of our community at <span class=
          "question">Queen Mary University of London</span> ("
          <strong>Company</strong></span> <span data-custom-class="body_text">",
          "<strong>we</strong>", "<strong>us</strong>", "<strong>our</strong>"). We are
          committed to protecting your personal information and your right to privacy. If you
          have any questions or concerns about this privacy notice, or our practices with
          regards to your personal information, please contact us at <span class=
          "question">gdconcertexplorer@gmail.com</span> .</span></span></span>
        </div>
    
        <div style="line-height: 1.5;">
          <span style="font-size: 15px;"><br /></span>
        </div>
    
        <div style="line-height: 1.5;">
          <span style="font-size: 15px;"><span style=
          "color: rgb(127, 127, 127);"><span data-custom-class="body_text">When you visit our
          website <span class="question"><a href="https://grateful-dead-live.github.io"
          target="_blank" data-custom-class=
          "link">https://grateful-dead-live.github.io</a></span> (the
          "<strong>Website</strong>"), <span style=
          "color: rgb(89, 89, 89);"><span data-custom-class="body_text"> </span></span> use
          our mobile application, <span style=
          "color: rgb(89, 89, 89);"><span data-custom-class="body_text"> </span></span> as
          the case may be (the "<strong>App</strong>") and more generally, use any of our
          services (the "<strong>Services</strong>", which include the&nbsp;</span>
          <span style="color: rgb(89, 89, 89);"><span data-custom-class=
          "body_text"><span style="color: rgb(89, 89, 89);"><span data-custom-class=
          "body_text">Website and </span></span></span> <span data-custom-class=
          "body_text"><span style="color: rgb(89, 89, 89);"><span data-custom-class=
          "body_text">App</span></span></span></span> <span data-custom-class="body_text">),
          we appreciate that you are trusting us with your personal information. We take your
          privacy very seriously. In this privacy notice, we seek to explain to you in the
          clearest way possible what information we collect, how we use it and what rights
          you have in relation to it. We hope you take some time to read through it
          carefully, as it is important. If there are any terms in this privacy notice that
          you do not agree with, please discontinue use of our Services
          immediately.</span></span></span>
        </div>
    
        <div style="line-height: 1.5;">
          <span style="font-size: 15px;"><br /></span>
        </div>
    
        <div style="line-height: 1.5;">
          <span style="font-size: 15px;"><span style=
          "color: rgb(127, 127, 127);"><span style="color: rgb(89, 89, 89);"><span data-custom-class="body_text">
          This privacy notice applies to all information collected through our Services
          (which, as described above, includes our&nbsp;</span> <span style=
          "color: rgb(89, 89, 89);"><span data-custom-class="body_text">Website and</span>
          <span data-custom-class="body_text">App </span></span> <span data-custom-class=
          "body_text">), as well as, any related services, sales, marketing or
          events.</span></span></span></span>
        </div>
    
        <div style="line-height: 1.5;">
          <span style="font-size: 15px;"><br /></span>
        </div>
    
        <div style="line-height: 1.5;">
          <span style="font-size: 15px;"><span style=
          "color: rgb(127, 127, 127);"><span style="color: rgb(89, 89, 89);"><span data-custom-class="body_text">
          <strong>Please read this privacy notice carefully as it will help you understand
          what we do with the information that we
          collect.</strong></span></span></span></span>
        </div>
    
        <div style="line-height: 1.5;">
          <span style="font-size: 15px;"><br /></span>
        </div>
    
        <div style="line-height: 1.5;">
          <span style="font-size: 15px;"><span style=
          "color: rgb(127, 127, 127);"><span style="color: rgb(0, 0, 0);"><strong><span data-custom-class="heading_1">
          TABLE OF CONTENTS</span></strong></span></span></span>
        </div>
    
        <div style="line-height: 1.5;">
          <span style="font-size: 15px;"><br /></span>
        </div>
    
        <div style="line-height: 1.5;">
          <span style="font-size: 15px;"><a data-custom-class="link" routerLink="." fragment="infocollect"><span style="color: rgb(89, 89, 89);">1. WHAT INFORMATION DO WE
          COLLECT?</span></a> </span>
        </div>
    
        <div style="line-height: 1.5;">
          <span style="font-size: 15px;"><span style=
          "color: rgb(89, 89, 89);"><a data-custom-class="link" routerLink="." fragment="infoshare">2. WILL
          YOUR INFORMATION BE SHARED WITH ANYONE?</a></span> <span style=
          "color: rgb(127, 127, 127);"><span style=
          "color: rgb(89, 89, 89);"><span data-custom-class="body_text">
          </span></span></span></span>
        </div>
    
        <div style="line-height: 1.5;">
          <span style="font-size: 15px;"><a data-custom-class="link" routerLink="." fragment="cookies"><span style="color: rgb(89, 89, 89);">3. DO WE USE COOKIES AND OTHER
          TRACKING TECHNOLOGIES?</span></a> <span style=
          "color: rgb(127, 127, 127);"><span style=
          "color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span style=
          "color: rgb(89, 89, 89);"> <span style="color: rgb(89, 89, 89);"> </span></span>
          </span></span></span></span>
        </div>
    
        <div style="line-height: 1.5;">
          <span style="font-size: 15px;"><a data-custom-class="link" routerLink="." fragment="intltransfers"><span style="color: rgb(89, 89, 89);">4. IS YOUR INFORMATION
          TRANSFERRED INTERNATIONALLY?</span></a> <span style=
          "color: rgb(127, 127, 127);"><span style=
          "color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span style=
          "color: rgb(89, 89, 89);"> </span></span></span></span></span>
        </div>
    
        <div style="line-height: 1.5;">
          <span style="font-size: 15px;"><a data-custom-class="link" routerLink="." fragment="inforetain"><span style="color: rgb(89, 89, 89);">5. HOW LONG DO WE KEEP YOUR
          INFORMATION?</span></a> </span>
        </div>
    
        <div style="line-height: 1.5;">
          <span style="font-size: 15px;"><a data-custom-class="link" routerLink="." fragment="infosafe"><span style="color: rgb(89, 89, 89);">6. HOW DO WE KEEP YOUR
          INFORMATION SAFE?</span></a> <span style="color: rgb(127, 127, 127);"><span style=
          "color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span style=
          "color: rgb(89, 89, 89);"> </span></span></span></span></span>
        </div>
    
        <div style="line-height: 1.5;">
          <span style="font-size: 15px;"><a data-custom-class="link" routerLink="." fragment="infominors"><span style="color: rgb(89, 89, 89);">7. DO WE COLLECT INFORMATION
          FROM MINORS?</span></a> </span>
        </div>
    
        <div style="line-height: 1.5;">
          <span style="font-size: 15px;"><span style=
          "color: rgb(89, 89, 89);"><a data-custom-class="link" routerLink="." fragment="privacyrights">8. WHAT
          ARE YOUR PRIVACY RIGHTS?</a></span></span>
        </div>
    
        <div style="line-height: 1.5;">
          <span style="font-size: 15px;"><a data-custom-class="link" routerLink="." fragment="DNT"><span style=
          "color: rgb(89, 89, 89);">9. CONTROLS FOR DO-NOT-TRACK FEATURES</span></a></span>
        </div>
    
        <div style="line-height: 1.5;">
          <span style="font-size: 15px;"><a data-custom-class="link" routerLink="." fragment="caresidents"><span style="color: rgb(89, 89, 89);">10. DO CALIFORNIA RESIDENTS
          HAVE SPECIFIC PRIVACY RIGHTS?</span></a></span>
        </div>
    
        <div style="line-height: 1.5;">
          <span style="font-size: 15px;"><a data-custom-class="link" routerLink="." fragment="policyupdates"><span style="color: rgb(89, 89, 89);">11. DO WE MAKE UPDATES TO
          THIS NOTICE?</span></a></span>
        </div>
    
        <div style="line-height: 1.5;">
          <a data-custom-class="link" routerLink="." fragment="contact"><span style=
          "color: rgb(89, 89, 89); font-size: 15px;">12. HOW CAN YOU CONTACT US ABOUT THIS
          NOTICE?</span></a>
        </div>
    
        <div style="line-height: 1.5;">
          <a data-custom-class="link" routerLink="." fragment="request"><span style=
          "color: rgb(89, 89, 89);">13. HOW CAN YOU REVIEW, UPDATE OR DELETE THE DATA WE
          COLLECT FROM YOU?</span></a>
        </div>
    
        <div style="line-height: 1.5;">
          <br />
        </div>
    
        <div id="infocollect" style="line-height: 1.5;">
          <span style="color: rgb(127, 127, 127);"><span style=
          "color: rgb(89, 89, 89); font-size: 15px;"><span style=
          "font-size: 15px; color: rgb(89, 89, 89);"><span style=
          "font-size: 15px; color: rgb(89, 89, 89);"><span id="control" style=
          "color: rgb(0, 0, 0);"><strong><span data-custom-class="heading_1">1. WHAT
          INFORMATION DO WE COLLECT?</span></strong> </span></span></span></span></span>
        </div>
    
        <div style="line-height: 1.5;">
          <span data-custom-class="heading_2" style="color: rgb(0, 0, 0);"><span style=
          "font-size: 15px;"><strong><u><br /></u></strong><strong>Information automatically
          collected</strong></span></span>
        </div>
    
        <div>
          <div>
            <br />
          </div>
    
          <div style="line-height: 1.5;">
            <span style="color: rgb(127, 127, 127);"><span style=
            "color: rgb(89, 89, 89); font-size: 15px;"><span data-custom-class=
            "body_text"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span style=
            "font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class=
            "body_text"><strong><em>In
            Short:&nbsp;</em></strong></span></span></span></span><span data-custom-class=
            "body_text"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span style=
            "font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class=
            "body_text"><strong><em>&nbsp;</em></strong><em>Some information &mdash; such as
            your Internet Protocol (IP) address and/or browser and device characteristics
            &mdash; is collected automatically when you visit our <span data-custom-class=
            "body_text"><em><span data-custom-class="body_text"><span style=
            "font-size: 15px;"><span data-custom-class="body_text"> Services
            </span></span></span></em></span>
            .</em></span></span></span></span></span></span>
          </div>
        </div>
    
        <div style="line-height: 1.5;">
          <br />
        </div>
    
        <div style="line-height: 1.5;">
          <span style="font-size: 15px; color: rgb(89, 89, 89);"><span style=
          "font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">We
          automatically collect certain information when you visit, use or navigate the
          <span style="font-size: 15px;"><span data-custom-class="body_text"> Services
          </span></span> . This information does not reveal your specific identity (like your
          name or contact information) but may include device and usage information, such as
          your IP address, browser and device characteristics, operating system, language
          preferences, referring URLs, device name, country, location, information about how
          and when you use our <span style="font-size: 15px;"><span data-custom-class=
          "body_text"> Services </span></span> and other technical information. This
          information is primarily needed to maintain the security and operation of our
          <span style="font-size: 15px;"><span data-custom-class="body_text"> Services
          </span></span> , and for our internal analytics and reporting
          purposes.</span></span></span>
        </div>
    
        <div style="line-height: 1.5;">
          <br />
        </div>
    
        <div style="line-height: 1.5;">
          <span style="font-size: 15px; color: rgb(89, 89, 89);"><span style=
          "font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">Like
          many businesses, we also collect information through cookies and similar
          technologies. </span></span></span>
        </div>
    
        <div style="line-height: 1.5;">
          <br />
        </div>
    
        <div style="line-height: 1.5;">
          <span style="font-size: 15px; color: rgb(89, 89, 89);"><span style=
          "font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">The
          information we collect includes:</span></span></span>
        </div>
    
        <ul>
          <li style="line-height: 1.5;"><span style=
          "font-size: 15px; color: rgb(89, 89, 89);"><span style=
          "font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class=
          "body_text"><em>Log and Usage Data.</em> Log and usage data is service-related,
          diagnostic, usage and performance information our servers automatically collect
          when you access or use our <span style="font-size: 15px;"><span style=
          "color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span style=
          "font-size: 15px;"><span data-custom-class="body_text"> Services
          </span></span></span></span></span> and which we record in log files. Depending on
          how you interact with us, this log data may include your IP address, device
          information, browser type and settings and information about your activity in the
          <span style="font-size: 15px;"><span style=
          "color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span style=
          "font-size: 15px;"><span data-custom-class="body_text"> Services
          </span></span></span></span> &nbsp;</span>(such as the date/time stamps associated
          with your usage, pages and files viewed, searches and other actions you take such
          as which features you use), device event information (such as system activity,
          error reports (sometimes called 'crash dumps') and hardware settings).
          </span></span></span></li>
        </ul>
    
        <ul>
          <li style="line-height: 1.5;"><span style=
          "font-size: 15px; color: rgb(89, 89, 89);"><span style=
          "font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class=
          "body_text"><em>Device Data.</em> We collect device data such as information about
          your computer, phone, tablet or other device you use to access the <span style=
          "font-size: 15px;"><span data-custom-class="body_text"> Services </span></span> .
          Depending on the device used, this device data may include information such as your
          IP address (or proxy server), device and application identification numbers,
          location, browser type, hardware model Internet service provider and/or mobile
          carrier, operating system and system configuration information.
          </span></span></span></li>
        </ul>
    
        <ul>
          <li style="line-height: 1.5;"><span style=
          "font-size: 15px; color: rgb(89, 89, 89);"><span style=
          "font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class=
          "body_text"><em>Location Data.</em> We collect location data such as information
          about your device's location, which can be either precise or imprecise. How much
          information we collect depends on the type and settings of the device you use to
          access the <span style="font-size: 15px;"><span style=
          "color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span style=
          "font-size: 15px;"><span data-custom-class="body_text"> Services
          </span></span></span></span></span> . For example, we may use GPS and other
          technologies to collect geolocation data that tells us your current location (based
          on your IP address). You can opt out of allowing us to collect this information
          either by refusing access to the information or by disabling your Location setting
          on your device. Note however, if you choose to opt out, you may not be able to use
          certain aspects of the Services. </span></span></span></li>
        </ul>
    
        <div></div>
    
        <div style="line-height: 1.5;">
          <span data-custom-class="heading_2" style="color: rgb(0, 0, 0);"><span style=
          "font-size: 15px;"><strong><u><br /></u></strong><strong>Information collected
          through our App</strong></span></span>
        </div>
    
        <div>
          <div>
            <br />
          </div>
    
          <div style="line-height: 1.5;">
            <span style="color: rgb(127, 127, 127);"><span style=
            "color: rgb(89, 89, 89); font-size: 15px;"><span data-custom-class=
            "body_text"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span style=
            "font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class=
            "body_text"><strong><em>In
            Short:&nbsp;</em></strong></span></span></span></span><span data-custom-class=
            "body_text"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span style=
            "font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class=
            "body_text"><strong><em>&nbsp;</em></strong><em>We collect information regarding
            your <span data-custom-class="body_text" style="font-size: 15px;"> geo-location,
            mobile device,</span> when you use our
            App.</em></span></span></span></span></span></span>
          </div>
        </div>
    
        <div style="line-height: 1.5;">
          <br />
        </div>
    
        <div style="line-height: 1.5;">
          <span style="font-size: 15px; color: rgb(89, 89, 89);"><span style=
          "font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">If
          you use our App, we also collect the following information:</span></span></span>
        </div>
    
        <ul>
          <li style="line-height: 1.5;"><span style=
          "font-size: 15px; color: rgb(89, 89, 89);"><span style=
          "font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class=
          "body_text"><em>Geo-Location Information.</em> We may request access or permission
          to and track location-based information from your mobile device, either
          continuously or while you are using our App, to provide certain location-based
          services. If you wish to change our access or permissions, you may do so in your
          device's settings. </span></span></span></li>
        </ul>
    
        <div>
          <ul>
            <li style="line-height: 1.5;"><span style=
            "font-size: 15px; color: rgb(89, 89, 89);"><span style=
            "font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class=
            "body_text"><em>Mobile Device Data.&nbsp;</em>We automatically collect device
            information (such as your mobile device ID, model and manufacturer), operating
            system, version information and system configuration information, device and
            application identification numbers, browser type and version, hardware model
            Internet service provider and/or mobile carrier, and Internet Protocol (IP)
            address (or proxy server). If you are using our App, we may also collect
            information about the phone network associated with your mobile device, your
            mobile device&rsquo;s operating system or platform, the type of mobile device you
            use, your mobile device&rsquo;s unique device ID and information about the
            features of our App you accessed. </span></span></span></li>
          </ul>
    
          <div>
            <div>
              <span class="block-component"></span>
    
              <div style="line-height: 1.5;">
                <span style="font-size: 15px; color: rgb(89, 89, 89);"><span style=
                "font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class=
                "body_text">This information is primarily needed to maintain the security and
                operation of our App, for troubleshooting and for our internal analytics and
                reporting purposes. </span></span></span>
              </div>
    
              <div style="line-height: 1.5;">
                <br />
              </div>
    
              <div id="infouse" style="line-height: 1.5;">
                <span style="color: rgb(127, 127, 127);"><span style=
                "color: rgb(89, 89, 89); font-size: 15px;"><span style=
                "font-size: 15px; color: rgb(89, 89, 89);"><span style=
                "font-size: 15px; color: rgb(89, 89, 89);"><span id="control" style=
                "color: rgb(0, 0, 0);"><strong><span data-custom-class="heading_1">2. HOW DO
                WE USE YOUR INFORMATION?</span></strong></span></span></span></span></span>
              </div>
    
              <div>
                <div style="line-height: 1.5;">
                  <br />
                </div>
    
                <div style="line-height: 1.5;">
                  <span style="color: rgb(127, 127, 127);"><span style=
                  "color: rgb(89, 89, 89); font-size: 15px;"><span data-custom-class=
                  "body_text"><span style=
                  "font-size: 15px; color: rgb(89, 89, 89);"><span style=
                  "font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class=
                  "body_text"><strong><em>In Short: &nbsp;</em></strong><em>We process your
                  information for purposes based on legitimate business interests, the
                  fulfillment of our contract with you, compliance with our legal
                  obligations, and/or your
                  consent.</em></span></span></span></span></span></span>
                </div>
              </div>
    
              <div style="line-height: 1.5;">
                <br />
              </div>
    
              <div style="line-height: 1.5;">
                <span style="font-size: 15px; color: rgb(89, 89, 89);"><span style=
                "font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class=
                "body_text">We use personal information collected via our <span style=
                "font-size: 15px;"><span data-custom-class="body_text"> Services
                </span></span> for a variety of business purposes described below. We process
                your personal information for these purposes in reliance on our legitimate
                business interests, in order to enter into or perform a contract with you,
                with your consent, and/or for compliance with our legal obligations. We
                indicate the specific processing grounds we rely on next to each purpose
                listed below.</span></span></span>
              </div>
    
              <div style="line-height: 1.5;">
                <br />
              </div>
    
              <div style="line-height: 1.5;">
                <span style="font-size: 15px; color: rgb(89, 89, 89);"><span style=
                "font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class=
                "body_text">We use the information we collect or
                receive:</span></span></span>
    
                <div>
                  <div>
                    <div>
                      <div>
                        <div>
                          <div>
                            <div>
                              <div>
                                <p style="font-size: 15px;"></p>
    
                                <p style="font-size: 15px;"></p>
    
                                <p style="font-size: 15px;"></p>
    
                                <p style="font-size: 15px;"></p>
    
                                <div>
                                  <div>
                                    <div>
                                      <div>
                                        <span style="font-size: 15px;"></span>
                                      </div>
    
                                      <ul>
                                        <li style="line-height: 1.5;"><span style=
                                        "font-size: 15px;"><span class=
                                        "question"><span data-custom-class=
                                        "body_text">Research</span></span></span></li>
                                      </ul>
    
                                      <div>
                                        <span style="font-size: 15px;"></span>
                                      </div>
    
                                      <div style="line-height: 1.5;">
                                        <br />
                                      </div>
    
                                      <div id="infoshare" style="line-height: 1.5;">
                                        <span style="color: rgb(127, 127, 127);"><span style=
                                        "color: rgb(89, 89, 89); font-size: 15px;"><span style="font-size: 15px; color: rgb(89, 89, 89);">
                                        <span style=
                                        "font-size: 15px; color: rgb(89, 89, 89);"><span id=
                                        "control" style=
                                        "color: rgb(0, 0, 0);"><strong><span data-custom-class="heading_1">
                                        3. WILL YOUR INFORMATION BE SHARED WITH
                                        ANYONE?</span></strong></span></span></span></span></span>
                                      </div>
    
                                      <div style="line-height: 1.5;">
                                        <br />
                                      </div>
    
                                      <div style="line-height: 1.5;">
                                        <span style=
                                        "font-size: 15px; color: rgb(89, 89, 89);"><span style="font-size: 15px; color: rgb(89, 89, 89);">
                                        <span data-custom-class="body_text"><strong><em>In
                                        Short:</em></strong><em>&nbsp; We only share
                                        information with your consent, to comply with laws,
                                        to provide you with services, to protect your rights,
                                        or to fulfill business
                                        obligations.</em></span></span></span>
                                      </div>
    
                                      <div style="line-height: 1.5;">
                                        <br />
                                      </div>
    
                                      <div style="line-height: 1.5;">
                                        <span style=
                                        "font-size: 15px; color: rgb(89, 89, 89);"><span style="font-size: 15px; color: rgb(89, 89, 89);">
                                        <span data-custom-class="body_text">We may process or
                                        share your data that we hold based on the following
                                        legal basis: </span></span></span>
                                      </div>
    
                                      <ul>
                                        <li style="line-height: 1.5;"><span style=
                                        "font-size: 15px; color: rgb(89, 89, 89);"><span style="font-size: 15px; color: rgb(89, 89, 89);">
                                        <span data-custom-class=
                                        "body_text"><strong>Consent:</strong> We may process
                                        your data if you have given us specific consent to
                                        use your personal information for a specific purpose.
                                        </span></span></span></li>
                                      </ul>
    
                                      <ul>
                                        <li style="line-height: 1.5;"><span style=
                                        "font-size: 15px; color: rgb(89, 89, 89);"><span style="font-size: 15px; color: rgb(89, 89, 89);">
                                        <span data-custom-class=
                                        "body_text"><strong>Legitimate Interests:</strong> We
                                        may process your data when it is reasonably necessary
                                        to achieve our legitimate business interests.
                                        </span></span></span></li>
                                      </ul>
    
                                      <ul>
                                        <li style="line-height: 1.5;"><span style=
                                        "font-size: 15px; color: rgb(89, 89, 89);"><span style="font-size: 15px; color: rgb(89, 89, 89);">
                                        <span data-custom-class=
                                        "body_text"><strong>Performance of a
                                        Contract:</strong> Where we have entered into a
                                        contract with you, we may process your personal
                                        information to fulfill the terms of our contract.
                                        </span></span></span></li>
                                      </ul>
    
                                      <ul>
                                        <li style="line-height: 1.5;"><span style=
                                        "font-size: 15px; color: rgb(89, 89, 89);"><span style="font-size: 15px; color: rgb(89, 89, 89);">
                                        <span data-custom-class="body_text"><strong>Legal
                                        Obligations:</strong> We may disclose your
                                        information where we are legally required to do so in
                                        order to comply with applicable law, governmental
                                        requests, a judicial proceeding, court order, or
                                        legal process, such as in response to a court order
                                        or a subpoena (including in response to public
                                        authorities to meet national security or law
                                        enforcement requirements). </span></span></span></li>
                                      </ul>
    
                                      <ul>
                                        <li style="line-height: 1.5;"><span style=
                                        "font-size: 15px; color: rgb(89, 89, 89);"><span style="font-size: 15px; color: rgb(89, 89, 89);">
                                        <span data-custom-class="body_text"><strong>Vital
                                        Interests:</strong> We may disclose your information
                                        where we believe it is necessary to investigate,
                                        prevent, or take action regarding potential
                                        violations of our policies, suspected fraud,
                                        situations involving potential threats to the safety
                                        of any person and illegal activities, or as evidence
                                        in litigation in which we are involved.
                                        </span></span></span></li>
                                      </ul>
    
                                      <div style="line-height: 1.5;">
                                        <span style=
                                        "font-size: 15px; color: rgb(89, 89, 89);"><span style="font-size: 15px; color: rgb(89, 89, 89);">
                                        <span data-custom-class="body_text">More
                                        specifically, we may need to process your data or
                                        share your personal information in the following
                                        situations:</span></span></span>
                                      </div>
    
                                      <ul>
                                        <li style="line-height: 1.5;"><span style=
                                        "font-size: 15px; color: rgb(89, 89, 89);"><span style="font-size: 15px; color: rgb(89, 89, 89);">
                                        <span data-custom-class="body_text"><strong>Business
                                        Transfers.</strong> We may share or transfer your
                                        information in connection with, or during
                                        negotiations of, any merger, sale of company assets,
                                        financing, or acquisition of all or a portion of our
                                        business to another
                                        company.</span></span></span></li>
                                      </ul>
    
                                      <div>
                                        <div>
                                          <ul>
                                            <li style="line-height: 1.5;"><span style=
                                            "font-size: 15px; color: rgb(89, 89, 89);"><span style="font-size: 15px; color: rgb(89, 89, 89);">
                                            <span data-custom-class=
                                            "body_text"><strong>Affiliates.</strong> We may
                                            share your information with our affiliates, in
                                            which case we will require those affiliates to
                                            honor this privacy notice. Affiliates include our
                                            parent company and any subsidiaries, joint
                                            venture partners or other companies that we
                                            control or that are under common control with us.
                                            </span></span></span></li>
                                          </ul>
    
                                          <!--ul>
                                            <li style="line-height: 1.5;"><span style=
                                            "font-size: 15px; color: rgb(89, 89, 89);"><span style="font-size: 15px; color: rgb(89, 89, 89);">
                                            <span data-custom-class=
                                            "body_text"><strong>Business
                                            Partners.&nbsp;</strong>We may share your
                                            information with our business partners to offer
                                            you certain products, services or
                                            promotions.</span></span></span></li>
                                          </ul-->
    
                                          <div>
                                            <div>
                                              <span style="font-size: 15px;"><span style=
                                              "color: rgb(89, 89, 89);"><span style=
                                              "font-size: 15px;"> </span></span></span>
    
                                              <div style="line-height: 1.5;"></div>
    
                                              <div style="line-height: 1.5;">
                                                <br />
                                              </div>
    
                                              <div id="cookies" style="line-height: 1.5;">
                                                <span style=
                                                "color: rgb(127, 127, 127);"><span style=
                                                "color: rgb(89, 89, 89); font-size: 15px;"><span style="font-size: 15px; color: rgb(89, 89, 89);">
                                                <span style=
                                                "font-size: 15px; color: rgb(89, 89, 89);"><span id="control"
                                                style=
                                                "color: rgb(0, 0, 0);"><strong><span data-custom-class="heading_1">
                                                4. DO WE USE COOKIES AND OTHER TRACKING
                                                TECHNOLOGIES?</span></strong></span></span></span></span></span>
                                              </div>
    
                                              <div style="line-height: 1.5;">
                                                <br />
                                              </div>
    
                                              <div style="line-height: 1.5;">
                                                <span style=
                                                "font-size: 15px; color: rgb(89, 89, 89);"><span style="font-size: 15px; color: rgb(89, 89, 89);">
                                                <span data-custom-class=
                                                "body_text"><strong><em>In
                                                Short:&nbsp;</em></strong><em>&nbsp;We may
                                                use cookies and other tracking technologies
                                                to collect and store your
                                                information.</em></span></span></span>
                                              </div>
    
                                              <div style="line-height: 1.5;">
                                                <br />
                                              </div>
    
                                              <div style="line-height: 1.5;">
                                                <span style=
                                                "font-size: 15px; color: rgb(89, 89, 89);"><span style="font-size: 15px; color: rgb(89, 89, 89);">
                                                <span data-custom-class="body_text">We may
                                                use cookies and similar tracking technologies
                                                (like web beacons and pixels) to access or
                                                store information. Specific information about
                                                how we use such technologies and how you can
                                                refuse certain cookies is set out in our
                                                Cookie Notice <span style=
                                                "color: rgb(89, 89, 89); font-size: 15px;"><span data-custom-class="body_text">
                                                .</span> <span style=
                                                "color: rgb(89, 89, 89); font-size: 15px;"><span style="font-size: 15px;">
                                                <span style=
                                                "color: rgb(89, 89, 89);"><span style=
                                                "font-size: 15px;"><span style=
                                                "color: rgb(89, 89, 89);">
                                                <span data-custom-class="body_text"></span>
                                                </span></span></span></span></span></span></span></span></span>
                                              </div>
    
                                              <div style="line-height: 1.5;">
                                                <br />
                                              </div>
    
                                              <div id="intltransfers" style=
                                              "line-height: 1.5;">
                                                <span style=
                                                "color: rgb(127, 127, 127);"><span style=
                                                "color: rgb(89, 89, 89); font-size: 15px;"><span style="font-size: 15px; color: rgb(89, 89, 89);">
                                                <span style=
                                                "font-size: 15px; color: rgb(89, 89, 89);"><span id="control"
                                                style=
                                                "color: rgb(0, 0, 0);"><strong><span data-custom-class="heading_1">
                                                5. IS YOUR INFORMATION TRANSFERRED
                                                INTERNATIONALLY?</span></strong>&nbsp;</span>&nbsp;</span>&nbsp;</span>&nbsp;</span>&nbsp;</span>
                                              </div>
    
                                              <div style="line-height: 1.5;">
                                                <br />
                                              </div>
    
                                              <div style="line-height: 1.5;">
                                                <span style=
                                                "font-size: 15px; color: rgb(89, 89, 89);"><span style="font-size: 15px; color: rgb(89, 89, 89);">
                                                <span data-custom-class=
                                                "body_text"><strong><em>In Short:
                                                &nbsp;</em></strong><em>We may transfer,
                                                store, and process your information in
                                                countries other than your
                                                own.</em></span></span></span>
                                              </div>
    
                                              <div style="line-height: 1.5;">
                                                <br />
                                              </div>
    
                                              <div style="line-height: 1.5;">
                                                <span style=
                                                "font-size: 15px; color: rgb(89, 89, 89);"><span style="font-size: 15px; color: rgb(89, 89, 89);">
                                                <span data-custom-class="body_text">Our
                                                servers are located in . If you are accessing
                                                our <span style=
                                                "font-size: 15px;"><span style=
                                                "color: rgb(89, 89, 89);"><span data-custom-class="body_text">
                                                <span style=
                                                "color: rgb(89, 89, 89); font-size: 15px;"><span data-custom-class="body_text">
                                                <span style="font-size: 15px;"><span style=
                                                "color: rgb(89, 89, 89);"><span data-custom-class="body_text">
                                                <span style="font-size: 15px;"><span style=
                                                "color: rgb(89, 89, 89);"><span data-custom-class="body_text">
                                                 Services
                                                </span></span></span></span></span></span></span></span></span></span></span>
                                                from outside , please be aware that your
                                                information may be transferred to, stored,
                                                and processed by us in our facilities and by
                                                those third parties with whom we may share
                                                your personal information (see
                                                "<a data-custom-class="link" routerLink="." fragment="#infoshare"><span style=
                                                "font-size: 15px;"><span style=
                                                "color: rgb(89, 89, 89);">WILL YOUR
                                                INFORMATION BE SHARED WITH
                                                ANYONE?</span></span></a>" above), in and
                                                other countries.</span></span></span>
                                              </div>
    
                                              <div style="line-height: 1.5;">
                                                <br />
                                              </div>
    
                                              <div style="line-height: 1.5;">
                                                <span style=
                                                "font-size: 15px; color: rgb(89, 89, 89);"><span style="font-size: 15px; color: rgb(89, 89, 89);">
                                                <span data-custom-class="body_text">If you
                                                are a resident in the European Economic Area,
                                                then these countries may not necessarily have
                                                data protection laws or other similar laws as
                                                comprehensive as those in your country. We
                                                will however take all necessary measures to
                                                protect your personal information in
                                                accordance with this privacy notice and
                                                applicable law. </span></span></span>
                                              </div>
    
                                              <div style="line-height: 1.5;">
                                                <br />
                                              </div>
    
                                              <div id="inforetain" style="line-height: 1.5;">
                                                <span style=
                                                "color: rgb(127, 127, 127);"><span style=
                                                "color: rgb(89, 89, 89); font-size: 15px;"><span style="font-size: 15px; color: rgb(89, 89, 89);">
                                                <span style=
                                                "font-size: 15px; color: rgb(89, 89, 89);"><span id="control"
                                                style=
                                                "color: rgb(0, 0, 0);"><strong><span data-custom-class="heading_1">
                                                6. HOW LONG DO WE KEEP YOUR
                                                INFORMATION?</span></strong></span></span></span></span></span>
                                              </div>
    
                                              <div style="line-height: 1.5;">
                                                <br />
                                              </div>
    
                                              <div style="line-height: 1.5;">
                                                <span style=
                                                "font-size: 15px; color: rgb(89, 89, 89);"><span style="font-size: 15px; color: rgb(89, 89, 89);">
                                                <span data-custom-class=
                                                "body_text"><strong><em>In
                                                Short:&nbsp;</em></strong><em>&nbsp;We keep
                                                your information for as long as necessary to
                                                fulfill the purposes outlined in this privacy
                                                notice unless otherwise required by
                                                law.</em></span></span></span>
                                              </div>
    
                                              <div style="line-height: 1.5;">
                                                <br />
                                              </div>
    
                                              <div style="line-height: 1.5;">
                                                <span style=
                                                "font-size: 15px; color: rgb(89, 89, 89);"><span style="font-size: 15px; color: rgb(89, 89, 89);">
                                                <span data-custom-class="body_text">We will
                                                only keep your personal information for as
                                                long as it is necessary for the purposes set
                                                out in this privacy notice, unless a longer
                                                retention period is required or permitted by
                                                law (such as tax, accounting or other legal
                                                requirements). No purpose in this notice will
                                                require us keeping your personal information
                                                for longer than <span class="question">six
                                                (6)</span> months past the termination of the
                                                user's account .</span></span></span>
                                              </div>
    
                                              <div style="line-height: 1.5;">
                                                <br />
                                              </div>
    
                                              <div style="line-height: 1.5;">
                                                <span style=
                                                "font-size: 15px; color: rgb(89, 89, 89);"><span style="font-size: 15px; color: rgb(89, 89, 89);">
                                                <span data-custom-class="body_text">When we
                                                have no ongoing legitimate business need to
                                                process your personal information, we will
                                                either delete or anonymize such information,
                                                or, if this is not possible (for example,
                                                because your personal information has been
                                                stored in backup archives), then we will
                                                securely store your personal information and
                                                isolate it from any further processing until
                                                deletion is possible. </span></span></span>
                                              </div>
    
                                              <div style="line-height: 1.5;">
                                                <br />
                                              </div>
    
                                              <div id="infosafe" style="line-height: 1.5;">
                                                <span style=
                                                "color: rgb(127, 127, 127);"><span style=
                                                "color: rgb(89, 89, 89); font-size: 15px;"><span style="font-size: 15px; color: rgb(89, 89, 89);">
                                                <span style=
                                                "font-size: 15px; color: rgb(89, 89, 89);"><span id="control"
                                                style=
                                                "color: rgb(0, 0, 0);"><strong><span data-custom-class="heading_1">
                                                7. HOW DO WE KEEP YOUR INFORMATION
                                                SAFE?</span></strong></span></span></span></span></span>
                                              </div>
    
                                              <div style="line-height: 1.5;">
                                                <br />
                                              </div>
    
                                              <div style="line-height: 1.5;">
                                                <span style=
                                                "font-size: 15px; color: rgb(89, 89, 89);"><span style="font-size: 15px; color: rgb(89, 89, 89);">
                                                <span data-custom-class=
                                                "body_text"><strong><em>In
                                                Short:&nbsp;</em></strong><em>&nbsp;We aim to
                                                protect your personal information through a
                                                system of organizational and technical
                                                security measures.</em></span></span></span>
                                              </div>
    
                                              <div style="line-height: 1.5;">
                                                <br />
                                              </div>
    
                                              <div style="line-height: 1.5;">
                                                <span style=
                                                "font-size: 15px; color: rgb(89, 89, 89);"><span style="font-size: 15px; color: rgb(89, 89, 89);">
                                                <span data-custom-class="body_text">We have
                                                implemented appropriate technical and
                                                organizational security measures designed to
                                                protect the security of any personal
                                                information we process. However, despite our
                                                safeguards and efforts to secure your
                                                information, no electronic transmission over
                                                the Internet or information storage
                                                technology can be guaranteed to be 100%
                                                secure, so we cannot promise or guarantee
                                                that hackers, cybercriminals, or other
                                                unauthorized third parties will not be able
                                                to defeat our security, and improperly
                                                collect, access, steal, or modify your
                                                information. Although we will do our best to
                                                protect your personal information,
                                                transmission of personal information to and
                                                from our <span style=
                                                "color: rgb(89, 89, 89); font-size: 15px;"><span data-custom-class="body_text">
                                                <span style="font-size: 15px;"><span style=
                                                "color: rgb(89, 89, 89);"><span data-custom-class="body_text">
                                                <span style="font-size: 15px;"><span style=
                                                "color: rgb(89, 89, 89);"><span data-custom-class="body_text">
                                                 Services
                                                </span></span></span></span></span></span></span></span>
                                                is at your own risk. You should only access
                                                the <span style=
                                                "color: rgb(89, 89, 89); font-size: 15px;"><span data-custom-class="body_text">
                                                <span style="font-size: 15px;"><span style=
                                                "color: rgb(89, 89, 89);"><span data-custom-class="body_text">
                                                <span style="font-size: 15px;"><span style=
                                                "color: rgb(89, 89, 89);"><span data-custom-class="body_text">
                                                 Services
                                                </span></span></span></span></span></span></span></span>
                                                within a secure environment.
                                                </span></span></span>
                                              </div>
    
                                              <div style="line-height: 1.5;">
                                                <br />
                                              </div>
    
                                              <div id="infominors" style="line-height: 1.5;">
                                                <span style=
                                                "color: rgb(127, 127, 127);"><span style=
                                                "color: rgb(89, 89, 89); font-size: 15px;"><span style="font-size: 15px; color: rgb(89, 89, 89);">
                                                <span style=
                                                "font-size: 15px; color: rgb(89, 89, 89);"><span id="control"
                                                style=
                                                "color: rgb(0, 0, 0);"><strong><span data-custom-class="heading_1">
                                                8. DO WE COLLECT INFORMATION FROM
                                                MINORS?</span></strong></span></span></span></span></span>
                                              </div>
    
                                              <div style="line-height: 1.5;">
                                                <br />
                                              </div>
    
                                              <div style="line-height: 1.5;">
                                                <span style=
                                                "font-size: 15px; color: rgb(89, 89, 89);"><span style="font-size: 15px; color: rgb(89, 89, 89);">
                                                <span data-custom-class=
                                                "body_text"><strong><em>In
                                                Short:</em></strong><em>&nbsp; We do not
                                                knowingly collect data from or market to
                                                children under 18 years of
                                                age.</em></span></span></span>
                                              </div>
    
                                              <div style="line-height: 1.5;">
                                                <br />
                                              </div>
    
                                              <div style="line-height: 1.5;">
                                                <span style=
                                                "font-size: 15px; color: rgb(89, 89, 89);"><span style="font-size: 15px; color: rgb(89, 89, 89);">
                                                <span data-custom-class="body_text">We do not
                                                knowingly solicit data from or market to
                                                children under 18 years of age. By using the
                                                <span style=
                                                "color: rgb(89, 89, 89); font-size: 15px;"><span data-custom-class="body_text">
                                                <span style="font-size: 15px;"><span style=
                                                "color: rgb(89, 89, 89);"><span data-custom-class="body_text">
                                                <span style="font-size: 15px;"><span style=
                                                "color: rgb(89, 89, 89);"><span data-custom-class="body_text">
                                                 Services
                                                </span></span></span></span></span></span></span></span>
                                                , you represent that you are at least 18 or
                                                that you are the parent or guardian of such a
                                                minor and consent to such minor
                                                dependent&rsquo;s use of the <span style=
                                                "color: rgb(89, 89, 89); font-size: 15px;"><span data-custom-class="body_text">
                                                <span style="font-size: 15px;"><span style=
                                                "color: rgb(89, 89, 89);"><span data-custom-class="body_text">
                                                <span style="font-size: 15px;"><span style=
                                                "color: rgb(89, 89, 89);"><span data-custom-class="body_text">
                                                 Services
                                                </span></span></span></span></span></span></span></span>
                                                . If we learn that personal information from
                                                users less than 18 years of age has been
                                                collected, we will deactivate the account and
                                                take reasonable measures to promptly delete
                                                such data from our records. If you become
                                                aware of any data we may have collected from
                                                children under age 18, please contact us at
                                                <span style=
                                                "color: rgb(89, 89, 89); font-size: 15px;"><span data-custom-class="body_text">
                                                 <span class=
                                                "question">gdconcertexplorer@gmail.com</span>
                                                </span></span> . </span></span></span>
                                              </div>
    
                                              <div style="line-height: 1.5;">
                                                <br />
                                              </div>
    
                                              <div id="privacyrights" style=
                                              "line-height: 1.5;">
                                                <span style=
                                                "color: rgb(127, 127, 127);"><span style=
                                                "color: rgb(89, 89, 89); font-size: 15px;"><span style="font-size: 15px; color: rgb(89, 89, 89);">
                                                <span style=
                                                "font-size: 15px; color: rgb(89, 89, 89);"><span id="control"
                                                style=
                                                "color: rgb(0, 0, 0);"><strong><span data-custom-class="heading_1">
                                                9. WHAT ARE YOUR PRIVACY
                                                RIGHTS?</span></strong></span></span></span></span></span>
                                              </div>
    
                                              <div style="line-height: 1.5;">
                                                <br />
                                              </div>
    
                                              <div style="line-height: 1.5;">
                                                <span style=
                                                "font-size: 15px; color: rgb(89, 89, 89);"><span style="font-size: 15px; color: rgb(89, 89, 89);">
                                                <span data-custom-class=
                                                "body_text"><strong><em>In
                                                Short:</em></strong><em>&nbsp; You may
                                                review, change, or terminate your account at
                                                any time.</em> </span></span></span>
                                              </div>
    
                                              <div style="line-height: 1.5;">
                                                <span style=
                                                "font-size: 15px; color: rgb(89, 89, 89);">&nbsp;</span>
                                              </div>
    
                                              <div style="line-height: 1.5;">
                                                <span style=
                                                "font-size: 15px; color: rgb(89, 89, 89);"><span style="font-size: 15px; color: rgb(89, 89, 89);">
                                                <span data-custom-class="body_text">If you
                                                are a resident in the European Economic Area
                                                and you believe we are unlawfully processing
                                                your personal information, you also have the
                                                right to complain to your local data
                                                protection supervisory authority. You can
                                                find their contact details here: <span style=
                                                "font-size: 15px;"><span style=
                                                "color: rgb(89, 89, 89);"><span data-custom-class="body_text">
                                                <span style=
                                                "color: rgb(48, 48, 241);"><span data-custom-class="body_text">
                                                <a data-custom-class="link" href=
                                                "http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
                                                rel="noopener noreferrer" target=
                                                "_blank"><span style=
                                                "font-size: 15px;">http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm</span></a></span></span></span></span></span>.</span></span></span>
                                              </div>
    
                                              <div style="line-height: 1.5;">
                                                <br />
                                              </div>
    
                                              <div style="line-height: 1.5;">
                                                <span style=
                                                "font-size: 15px; color: rgb(89, 89, 89);"><span style="font-size: 15px; color: rgb(89, 89, 89);">
                                                <span data-custom-class="body_text">If you
                                                are a resident in Switzerland, the contact
                                                details for the data protection authorities
                                                are available here: <span style=
                                                "font-size: 15px;"><span style=
                                                "color: rgb(89, 89, 89);"><span data-custom-class="body_text">
                                                <span style=
                                                "color: rgb(48, 48, 241);"><span data-custom-class="body_text">
                                                <span style=
                                                "font-size: 15px;"><a data-custom-class=
                                                "link" href=
                                                "https://www.edoeb.admin.ch/edoeb/en/home.html"
                                                rel="noopener noreferrer" target=
                                                "_blank">https://www.edoeb.admin.ch/edoeb/en/home.html</a></span></span></span></span></span></span>.
                                                </span></span></span>
                                              </div>
    
                                              <div style="line-height: 1.5;">
                                                <span data-custom-class="heading_2" style=
                                                "color: rgb(0, 0, 0);"><span style=
                                                "font-size: 15px;"><strong><u><br /></u></strong><strong>Account
                                                Information</strong></span></span>
                                              </div>
    
                                              <div>
                                                <div>
                                                  <br />
                                                </div>
    
                                                <div style="line-height: 1.5;">
                                                  <span style=
                                                  "color: rgb(127, 127, 127);"><span style=
                                                  "color: rgb(89, 89, 89); font-size: 15px;"><span data-custom-class="body_text">
                                                  <span style=
                                                  "font-size: 15px; color: rgb(89, 89, 89);"><span style="font-size: 15px; color: rgb(89, 89, 89);">
                                                  <span data-custom-class="body_text">If you
                                                  would at any time like to review or change
                                                  the information in your account or
                                                  terminate your account, you
                                                  can:</span></span></span></span></span></span>
                                                </div>
    
                                                <ul>
                                                  <li style="line-height: 1.5;"><span style=
                                                  "color: rgb(127, 127, 127);"><span style=
                                                  "color: rgb(89, 89, 89); font-size: 15px;"><span data-custom-class="body_text">
                                                  <span style=
                                                  "font-size: 15px; color: rgb(89, 89, 89);"><span style="font-size: 15px; color: rgb(89, 89, 89);">
                                                  <span data-custom-class=
                                                  "body_text"><span class="question">Contact
                                                  us using the contact information
                                                  provided.</span></span></span></span></span></span></span></li>
                                                </ul>
    
                                                <div style="line-height: 1.5;">
                                                  <span style=
                                                  "font-size: 15px; color: rgb(89, 89, 89);"><span style="font-size: 15px; color: rgb(89, 89, 89);">
                                                  <span data-custom-class="body_text">Upon
                                                  your request to terminate your account, we
                                                  will deactivate or delete your account and
                                                  information from our active databases.
                                                  However, we may retain some information in
                                                  our files to prevent fraud, troubleshoot
                                                  problems, assist with any investigations,
                                                  enforce our Terms of Use and/or comply with
                                                  applicable legal requirements. <span style=
                                                  "font-size: 15px;"><span style=
                                                  "color: rgb(89, 89, 89);"><span style=
                                                  "font-size: 15px;"><span style=
                                                  "color: rgb(89, 89, 89);"><span data-custom-class="body_text">
                                                  <span style="font-size: 15px;"><span style=
                                                  "color: rgb(89, 89, 89);">
                                                  </span></span></span></span></span></span></span></span></span></span>
                                                </div>
                                              </div>
    
                                              <div style="line-height: 1.5;">
                                                <br />
                                              </div>
    
                                              <div style="line-height: 1.5;">
                                                <span style=
                                                "font-size: 15px; color: rgb(89, 89, 89);"><span style="font-size: 15px; color: rgb(89, 89, 89);">
                                                <span data-custom-class=
                                                "body_text"><strong><u>Cookies and similar
                                                technologies:</u></strong> Most Web browsers
                                                are set to accept cookies by default. If you
                                                prefer, you can usually choose to set your
                                                browser to remove cookies and to reject
                                                cookies. If you choose to remove cookies or
                                                reject cookies, this could affect certain
                                                features or services of our <span style=
                                                "font-size: 15px;"><span style=
                                                "color: rgb(89, 89, 89);"><span data-custom-class="body_text">
                                                <span style=
                                                "color: rgb(89, 89, 89); font-size: 15px;"><span data-custom-class="body_text">
                                                <span style="font-size: 15px;"><span style=
                                                "color: rgb(89, 89, 89);"><span data-custom-class="body_text">
                                                <span style="font-size: 15px;"><span style=
                                                "color: rgb(89, 89, 89);"><span data-custom-class="body_text">
                                                 Services
                                                </span></span></span></span></span></span></span></span></span></span></span>
                                                . To opt-out of interest-based advertising by
                                                advertisers on our <span style=
                                                "font-size: 15px;"><span style=
                                                "color: rgb(89, 89, 89);"><span data-custom-class="body_text">
                                                <span style=
                                                "color: rgb(89, 89, 89); font-size: 15px;"><span data-custom-class="body_text">
                                                <span style="font-size: 15px;"><span style=
                                                "color: rgb(89, 89, 89);"><span data-custom-class="body_text">
                                                <span style="font-size: 15px;"><span style=
                                                "color: rgb(89, 89, 89);"><span data-custom-class="body_text">
                                                 Services
                                                </span></span></span></span></span></span></span></span></span></span></span>
                                                visit <span style=
                                                "color: rgb(48, 48, 241);"><span data-custom-class="body_text">
                                                <a data-custom-class="link" href=
                                                "http://www.aboutads.info/choices/" rel=
                                                "noopener noreferrer" target=
                                                "_blank"><span style=
                                                "font-size: 15px;">http://www.aboutads.info/choices/</span></a></span></span>.
                                                <span style="font-size: 15px;"><span style=
                                                "color: rgb(89, 89, 89);"><span data-custom-class="body_text">
                                                </span> </span></span></span></span></span>
                                              </div>
    
                                              <div style="line-height: 1.5;">
                                                <br />
                                              </div>
    
                                              <div style="line-height: 1.5;">
                                                <span style=
                                                "font-size: 15px; color: rgb(89, 89, 89);"><span style="font-size: 15px; color: rgb(89, 89, 89);">
                                                <span data-custom-class=
                                                "body_text"><strong><u>Opting out of email
                                                marketing:</u></strong> You can unsubscribe
                                                from our marketing email list at any time by
                                                clicking on the unsubscribe link in the
                                                emails that we send or by contacting us using
                                                the details provided below. You will then be
                                                removed from the marketing email list &mdash;
                                                however, we may still communicate with you,
                                                for example to send you service-related
                                                emails that are necessary for the
                                                administration and use of your account, to
                                                respond to service requests, or for other
                                                non-marketing purposes. To otherwise opt-out,
                                                you may: </span></span></span>
                                              </div>
    
                                              <ul>
                                                <li style="line-height: 1.5;"><span class=
                                                "question"><span data-custom-class=
                                                "body_text">Contact us using the contact
                                                information provided.</span></span></li>
                                              </ul>
    
                                              <div style="line-height: 1.5;">
                                                <br />
                                              </div>
    
                                              <div id="DNT" style="line-height: 1.5;">
                                                <span style=
                                                "color: rgb(127, 127, 127);"><span style=
                                                "color: rgb(89, 89, 89); font-size: 15px;"><span style="font-size: 15px; color: rgb(89, 89, 89);">
                                                <span style=
                                                "font-size: 15px; color: rgb(89, 89, 89);"><span id="control"
                                                style=
                                                "color: rgb(0, 0, 0);"><strong><span data-custom-class="heading_1">
                                                10. CONTROLS FOR DO-NOT-TRACK
                                                FEATURES</span></strong></span></span></span></span></span>
                                              </div>
    
                                              <div style="line-height: 1.5;">
                                                <br />
                                              </div>
    
                                              <div style="line-height: 1.5;">
                                                <span style=
                                                "font-size: 15px; color: rgb(89, 89, 89);"><span style="font-size: 15px; color: rgb(89, 89, 89);">
                                                <span data-custom-class="body_text">Most web
                                                browsers and some mobile operating systems
                                                and mobile applications include a
                                                Do-Not-Track ("DNT") feature or setting you
                                                can activate to signal your privacy
                                                preference not to have data about your online
                                                browsing activities monitored and collected.
                                                At this stage no uniform technology standard
                                                for recognizing and implementing DNT signals
                                                has been finalized. As such, we do not
                                                currently respond to DNT browser signals or
                                                any other mechanism that automatically
                                                communicates your choice not to be tracked
                                                online. If a standard for online tracking is
                                                adopted that we must follow in the future, we
                                                will inform you about that practice in a
                                                revised version of this privacy
                                                notice.</span></span>&nbsp;</span>
                                              </div>
    
                                              <div style="line-height: 1.5;">
                                                <br />
                                              </div>
    
                                              <div id="caresidents" style=
                                              "line-height: 1.5;">
                                                <span style=
                                                "color: rgb(127, 127, 127);"><span style=
                                                "color: rgb(89, 89, 89); font-size: 15px;"><span style="font-size: 15px; color: rgb(89, 89, 89);">
                                                <span style=
                                                "font-size: 15px; color: rgb(89, 89, 89);"><span id="control"
                                                style=
                                                "color: rgb(0, 0, 0);"><strong><span data-custom-class="heading_1">
                                                11. DO CALIFORNIA RESIDENTS HAVE SPECIFIC
                                                PRIVACY
                                                RIGHTS?</span></strong></span></span></span></span></span>
                                              </div>
    
                                              <div style="line-height: 1.5;">
                                                <br />
                                              </div>
    
                                              <div style="line-height: 1.5;">
                                                <span style=
                                                "font-size: 15px; color: rgb(89, 89, 89);"><span style="font-size: 15px; color: rgb(89, 89, 89);">
                                                <span data-custom-class=
                                                "body_text"><strong><em>In
                                                Short:&nbsp;</em></strong><em>&nbsp;Yes, if
                                                you are a resident of California, you are
                                                granted specific rights regarding access to
                                                your personal
                                                information.</em></span></span></span>
                                              </div>
    
                                              <div style="line-height: 1.5;">
                                                <br />
                                              </div>
    
                                              <div style="line-height: 1.5;">
                                                <span style=
                                                "font-size: 15px; color: rgb(89, 89, 89);"><span style="font-size: 15px; color: rgb(89, 89, 89);">
                                                <span data-custom-class=
                                                "body_text">California Civil Code Section
                                                1798.83, also known as the "Shine The Light"
                                                law, permits our users who are California
                                                residents to request and obtain from us, once
                                                a year and free of charge, information about
                                                categories of personal information (if any)
                                                we disclosed to third parties for direct
                                                marketing purposes and the names and
                                                addresses of all third parties with which we
                                                shared personal information in the
                                                immediately preceding calendar year. If you
                                                are a California resident and would like to
                                                make such a request, please submit your
                                                request in writing to us using the contact
                                                information provided
                                                below.</span></span></span>
                                              </div>
    
                                              <div style="line-height: 1.5;">
                                                <br />
                                              </div>
    
                                              <div style="line-height: 1.5;">
                                                <span style=
                                                "font-size: 15px; color: rgb(89, 89, 89);"><span style="font-size: 15px; color: rgb(89, 89, 89);">
                                                <span data-custom-class="body_text">If you
                                                are under 18 years of age, reside in
                                                California, and have a registered account
                                                with <span style=
                                                "color: rgb(89, 89, 89); font-size: 15px;"><span data-custom-class="body_text">
                                                <span style=
                                                "color: rgb(89, 89, 89); font-size: 15px;"><span data-custom-class="body_text">
                                                <span style="font-size: 15px;"><span style=
                                                "color: rgb(89, 89, 89);"><span data-custom-class="body_text">
                                                 a Service
                                                </span></span></span></span></span></span></span>
                                                , you have the right to request removal of
                                                unwanted data that you publicly post on the
                                                <span style=
                                                "color: rgb(89, 89, 89); font-size: 15px;"><span data-custom-class="body_text">
                                                <span style=
                                                "color: rgb(89, 89, 89); font-size: 15px;"><span data-custom-class="body_text">
                                                <span style="font-size: 15px;"><span style=
                                                "color: rgb(89, 89, 89);"><span data-custom-class="body_text">
                                                 Services
                                                </span></span></span></span></span></span></span>
                                                . To request removal of such data, please
                                                contact us using the contact information
                                                provided below, and include the email address
                                                associated with your account and a statement
                                                that you reside in California. We will make
                                                sure the data is not publicly displayed on
                                                the <span style=
                                                "color: rgb(89, 89, 89); font-size: 15px;"><span data-custom-class="body_text">
                                                <span style=
                                                "color: rgb(89, 89, 89); font-size: 15px;"><span data-custom-class="body_text">
                                                <span style="font-size: 15px;"><span style=
                                                "color: rgb(89, 89, 89);"><span data-custom-class="body_text">
                                                 Services
                                                </span></span></span></span></span></span></span>
                                                , but please be aware that the data may not
                                                be completely or comprehensively removed from
                                                all our systems (e.g. backups, etc.).
                                                </span></span></span>
                                              </div>
    
                                              <div style="line-height: 1.5;">
                                                <br />
                                              </div>
    
                                              <div id="policyupdates" style=
                                              "line-height: 1.5;">
                                                <span style=
                                                "color: rgb(127, 127, 127);"><span style=
                                                "color: rgb(89, 89, 89); font-size: 15px;"><span style="font-size: 15px; color: rgb(89, 89, 89);">
                                                <span style=
                                                "font-size: 15px; color: rgb(89, 89, 89);"><span id="control"
                                                style=
                                                "color: rgb(0, 0, 0);"><strong><span data-custom-class="heading_1">
                                                12. DO WE MAKE UPDATES TO THIS
                                                NOTICE?</span></strong>&nbsp;</span>&nbsp;</span>&nbsp;</span>&nbsp;</span>&nbsp;</span>
                                              </div>
    
                                              <div style="line-height: 1.5;">
                                                <em><br /></em>
                                              </div>
    
                                              <div style="line-height: 1.5;">
                                                <span style=
                                                "font-size: 15px; color: rgb(89, 89, 89);"><span style="font-size: 15px; color: rgb(89, 89, 89);">
                                                <span data-custom-class=
                                                "body_text"><em><strong>In
                                                Short:&nbsp;</strong> Yes, we will update
                                                this notice as necessary to stay compliant
                                                with relevant laws.</em></span></span></span>
                                              </div>
    
                                              <div style="line-height: 1.5;">
                                                <br />
                                              </div>
    
                                              <div style="line-height: 1.5;">
                                                <span style=
                                                "font-size: 15px; color: rgb(89, 89, 89);"><span style="font-size: 15px; color: rgb(89, 89, 89);">
                                                <span data-custom-class="body_text">We may
                                                update this privacy notice from time to time.
                                                The updated version will be indicated by an
                                                updated "Revised" date and the updated
                                                version will be effective as soon as it is
                                                accessible. If we make material changes to
                                                this privacy notice, we may notify you either
                                                by prominently posting a notice of such
                                                changes or by directly sending you a
                                                notification. We encourage you to review this
                                                privacy notice frequently to be informed of
                                                how we are protecting your
                                                information.</span></span></span>
                                              </div>
    
                                              <div style="line-height: 1.5;">
                                                <br />
                                              </div>
    
                                              <div id="contact" style="line-height: 1.5;">
                                                <span style=
                                                "color: rgb(127, 127, 127);"><span style=
                                                "color: rgb(89, 89, 89); font-size: 15px;"><span style="font-size: 15px; color: rgb(89, 89, 89);">
                                                <span style=
                                                "font-size: 15px; color: rgb(89, 89, 89);"><span id="control"
                                                style=
                                                "color: rgb(0, 0, 0);"><strong><span data-custom-class="heading_1">
                                                13. HOW CAN YOU CONTACT US ABOUT THIS
                                                NOTICE?</span></strong>&nbsp;</span>&nbsp;</span>&nbsp;</span>&nbsp;</span>&nbsp;</span>
                                              </div>
    
                                              <div style="line-height: 1.5;">
                                                <br />
                                              </div>
    
                                              <div style="line-height: 1.5;">
                                                <span style=
                                                "font-size: 15px; color: rgb(89, 89, 89);"><span style="font-size: 15px; color: rgb(89, 89, 89);">
                                                <span data-custom-class="body_text">If you
                                                have questions or comments about this notice,
                                                you may <span style=
                                                "color: rgb(89, 89, 89); font-size: 15px;"><span data-custom-class="body_text">
                                                email us at <span class=
                                                "question">gdconcertexplorer@gmail.com</span>
                                                </span></span> 
                                            </span></span></span>
                                            </div>
                                                <!--
                                                or by post
                                                to:</span></span></span>
                                              </div>
    
                                              <div style="line-height: 1.5;">
                                                <br />
                                              </div>
    
                                              <div style="line-height: 1.5;">
                                                <span style=
                                                "font-size: 15px; color: rgb(89, 89, 89);"><span style="font-size: 15px; color: rgb(89, 89, 89);">
                                                <span data-custom-class=
                                                "body_text"><span style=
                                                "font-size: 15px;"><span style=
                                                "color: rgb(89, 89, 89);"><span style=
                                                "color: rgb(89, 89, 89);"><span data-custom-class="body_text">
                                                <span class="question">Queen Mary University
                                                of
                                                London</span></span></span></span></span></span>
                                                </span></span>
                                              </div>
    
                                              <div style="line-height: 1.5;">
                                                <span style=
                                                "font-size: 15px;"><span data-custom-class=
                                                "body_text"><span class="question">Mile End
                                                Road</span> </span></span>
                                              </div>
    
                                              <div style="line-height: 1.5;">
                                                <span style=
                                                "font-size: 15px;"><span data-custom-class=
                                                "body_text"><span class=
                                                "question">London</span> <span style=
                                                "color: rgb(89, 89, 89);"><span style=
                                                "font-size: 15px;"> <span class="question">E1
                                                4NS</span></span> <span style=
                                                "font-size: 15px;">
                                                </span></span></span></span>
                                              </div>
    
                                              <div style="line-height: 1.5;">
                                                <span style=
                                                "font-size: 15px;"><span data-custom-class=
                                                "body_text"> <span class="question">United
                                                Kingdom</span> <span style=
                                                "font-size: 15px;"><span data-custom-class=
                                                "body_text"><span style=
                                                "color: rgb(89, 89, 89);"><span class=
                                                "statement-end-if-in-editor"><span style=
                                                "font-size: 15px;"><span data-custom-class=
                                                "body_text"><span style=
                                                "color: rgb(89, 89, 89);">
                                                </span></span></span>
                                                </span></span></span></span></span></span>
                                              </div>
                                              -->
                                              <div style="line-height: 1.5;">
                                                <br />
                                              </div>
    
                                              <div id="request" style="line-height: 1.5;">
                                                <span style=
                                                "color: rgb(127, 127, 127);"><span style=
                                                "color: rgb(89, 89, 89); font-size: 15px;"><span style="font-size: 15px; color: rgb(89, 89, 89);">
                                                <span style=
                                                "font-size: 15px; color: rgb(89, 89, 89);"><span id="control"
                                                style=
                                                "color: rgb(0, 0, 0);"><strong><span data-custom-class="heading_1">
                                                14. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE
                                                DATA WE COLLECT FROM
                                                YOU?</span></strong>&nbsp;</span>&nbsp;</span>&nbsp;</span>&nbsp;</span>&nbsp;</span>
                                              </div>
                                              
                                              <div style="line-height: 1.5;">
                                                <br />
                                              </div>
    
                                              <div style="line-height: 1.5;">
                                                <span style=
                                                "font-size: 15px; color: rgb(89, 89, 89);"><span style="font-size: 15px; color: rgb(89, 89, 89);">
                                                <span data-custom-class="body_text">Based on
                                                the applicable laws of your country, you may
                                                have the right to request access to the
                                                personal information we collect from you,
                                                change that information, or delete it in some
                                                circumstances. To request to review, update,
                                                or delete your personal information, email us at gdconcertexplorer@gmail.com</span>
                                                <!--submit a request form by
                                                writing to gdconcertexplorer@gmail.com</span>
                                                clicking&nbsp;</span><span style=
                                                "color: rgb(48, 48, 241);"><span data-custom-class="body_text"><span style="font-size: 15px;"><a data-custom-class="link"
                                                href=
                                                "https://app.termly.io/notify/466cb294-d346-4812-ab70-2e5c61d9ca2b"
                                                rel="noopener noreferrer" target=
                                                "_blank">here</a></span></span></span-->
                                                <span data-custom-class="body_text">. We will
                                                respond to your request within 30
                                                days.</span></span></span>
                                              </div>
                                            </div>
    
                                           
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>



<br>
    <div style=
    "color: #595959;font-size: 10px;font-family: Arial;padding-top:16px;">
    This privacy policy was created using <a style=
    "color: rgb(48, 48, 241) !important;" href=
    "https://termly.io/products/privacy-policy-generator/?ftseo">
      Termly&rsquo;s Privacy Policy Generator</a>.
    </div>
</div>
<p [style.margin-bottom]="'150px'"></p>