
<gd-header *ngIf="!artist" style="position: fixed; width: 100%" title=" " userId='{{ currentUser.userId }}'></gd-header>
<div *ngIf="!artist && spinTime" class="wait">
  <div class="loader"></div>
</div>
<div *ngIf="!artist"  && spinTimeclass="wait"><img src="/assets/bearsanimated.webp" width="160"></div>

<div *ngIf="artist" fxLayout="column">
  <gd-header *ngIf="artist" title="{{ artist.name }}"
    subtitle="{{ artist.instruments.join(', ').split('_').join(' ') }}" userId='{{ currentUser.userId }}'>
  </gd-header>
  
  <div class="gd-container" fxLayout="row" fxLayout.xs="column" style="margin-top:10px;">
    <img *ngIf="artist.thumbnail" class="image gd-container"
      [defaultImage]="'assets/gd_placeholder.png'"
      [lazyLoad]="artist.thumbnail" [style.object-fit]="'contain'"
      fxFlex="50">
    <div class="gd-container" fxFlex="50">
      {{ artist.comment }}
    </div>
  </div>
  
  <p class="gd-section">Songs composed by {{artist.name}}:</p>
  
  <div class="gd-container" fxLayout="column">
    <div class="gd-container" fxLayout="column">
      <div *ngFor="let song of artist.compositions" fxLayout="row" fxLayoutAlign="space-between center">
        <span [routerLink]="['/song', song.id]">{{ song.name }}</span>

      </div>
    </div>
  </div>
  
  <p *ngIf="artist.eventIds.length" class="gd-section">{{artist.name}} played with the Dead on:</p>
  
  <gd-shows [eventIds]="artist.eventIds"></gd-shows>
  
  <gd-comments [userName]="currentUser.userName" [currentUserId]="currentUser.userId" [title]="artist.name+', '+artist.instruments.join(', ').split('_').join(' ')"></gd-comments>
  <br>
  <gd-sharebuttons></gd-sharebuttons>
</div>
<p [style.margin-bottom]="'75px'"></p>
