<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
<div *ngIf="title" fxLayout="row" fxLayout.xs="column"
    [style.background-color]="'#ededed'" [style.background-size]="'cover'"
    [style.background-position]="'center'" [style.padding]="'3px'">
  <div class="container" fxLayout="column" fxFlex="1 1 auto">
    <span>
        <button mat-button [matMenuTriggerFor]="menu"><img src="assets/gd_logo2.png" height="25"></button>        
        <mat-menu #menu="matMenu">
          <!-- <button *ngFor="let v of views" mat-menu-item [routerLink]="[v.url]">{{v.name}}</button> -->
          <button mat-menu-item routerLink="/mapselect" *ngIf="userId != 'None'">Concert Map</button>
          <button mat-menu-item routerLink="/profile" *ngIf="userId != 'None'">Profile Page</button>
          <button mat-menu-item (click)="auth.login()" *ngIf="userId == 'None'">Log In</button>
          <button mat-menu-item (click)="logout()" *ngIf="userId != 'None'">Log Out</button>
          <!--button mat-menu-item (click)="auth.logout()" *ngIf="userId">Log Out</button-->
          <button mat-menu-item *ngIf="userId != 'None'" routerLink="/index">Index</button>
          <button mat-menu-item routerLink="/about">About</button>
          <button mat-menu-item routerLink="/dataprivacy">Privacy</button>
          <button mat-menu-item (click)="sendFeedback()" class="donate-menu-button">Send feedback</button>
          <button mat-menu-item (click)="buyMeACoffee()" class="donate-menu-button">Donate</button>
        </mat-menu>
    </span>
  </div>


  <div class="container hf1" fxLayout="column" fxFlex="1 1 auto" [style.padding]="'4px'">
    <span>
      {{ title }} {{ subtitle }}
      <span *ngIf="!((router.url == '/about') || (router.url == '/mapselect') || (router.url == '/profile') || 
                      (router.url == '/dataprivacy') || (router.url.startsWith('/playlist')))">
        <button *ngIf="userId" mat-icon-button color="" (click)="onBookmarkButton()">
          <mat-icon>{{bookmarked ? 'bookmark' : 'bookmark_border'}}</mat-icon>
        </button>

      <!--span *ngIf="auth.loggedIn && !((router.url == '/about') || (router.url == '/mapselect') || (router.url == '/profile') || (router.url == '/dataprivacy'))"-->
        <button *ngIf="userId != None" mat-icon-button color="" (click)="onLikeButton()">
          <mat-icon>{{liked ? 'thumb_up_alt' : 'thumb_up_off_alt'}}</mat-icon>
        </button>
        <!--button *ngIf="!userId" mat-icon-button color="" style="pointer-events: none;">
          <mat-icon >thumb_up_off_alt</mat-icon>
        </button-->
        {{likes}}
      </span>
     

    </span>

    
    
    
    
  </div>
  <!--<div fxFlex="5 1 auto"></div>-->
  <ng-content></ng-content>

  <!--button mat-button style="margin-bottom: 0px;"><img src="/assets/buymeacoffee.png" height="30px" width="121px" (click)="buyMeACoffee()"/></button-->

  <p *ngIf="!searchState" [style.margin-right]="'25px'"></p>
  <div *ngIf="searchState" [style.margin-top]="'8px'" [style.margin-right]="'5px'"><mat-spinner diameter="20"></mat-spinner></div>
  <input [disabled]="searchState==1" #searchfield placeholder="Search" (keydown.enter)="onSubmit(searchfield.value)">


  
 

</div>