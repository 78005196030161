
<div *ngIf="!loaded && spinTime" class="wait">
  <div class="loader"></div>
</div>

<div *ngIf="!loaded && spinTime" class="wait"><img src="/assets/bearsanimated.webp" width="160"></div>

<div *ngIf="!timeOut && !loaded && spinTime" class="wait">connecting to database</div>
<div *ngIf="timeOut && !loaded" class="wait">loading seems to take a while! retrying...</div>

<div id="maploaded" [style.visibility]="!loaded ? 'hidden' : 'visible' ">
  <div class="center">
    <select [(ngModel)]="selectedTour" (ngModelChange)="tourChanged($event)">
        <option *ngFor="let l of layerNames" ngValue="{{l}}"> {{l}}</option>
    </select>
  </div>
  <div id="MapContainer"
    leaflet
    [leafletOptions]="mapOptions"
    (leafletMapReady)="onMapReady($event)">
  </div>
</div> 


