
<div>
    Please log in to continue.
</div>
<br>
<button mat-flat-button (click)="auth.login()">
    log in
</button>




