<div *ngIf="playlist" fxLayout="column">
  <gd-header title='Playlist '
    subtitle=' – "{{ playlist.name }}"' userId='{{ currentUser.userId }}'>
  </gd-header>

  <div class="gd-container" style="margin-top:35px;">
    the playlist "{{ playlist.name }}" has been loaded in the audio player:
    <br><br>
    <div *ngFor="let t of playlist.playlist; index as i">
      {{i+1}}. {{ t.title }}
    </div>


</div>