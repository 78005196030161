<gd-header *ngIf="!location" style="position: fixed; width: 100%" title=" " userId='{{ currentUser.userId }}'></gd-header>
<div *ngIf="!location && spinTime" class="wait">
  <div class="loader"></div>
</div>
<div *ngIf="!location && spinTime" class="wait && spinTime"><img src="/assets/bearsanimated.webp" width="160"></div>

<div *ngIf="location" fxLayout="column">
  <gd-header *ngIf="location" title='{{ location.name }}'
    subtitle='{{ location.state }}' imageUrl="{{ location.image }}" userId='{{ currentUser.userId }}'>
  </gd-header>
  <div class="container" fxLayout="row wrap" fxLayout.xs="column" fxFlex="100" style="margin-top:30px;">
    <div class="gd-container" fxLayout="column" fxFlex="1 1 33%" fxFlex.sm="1 1 50%">
      <p *ngIf="data.event">The Band played on {{ data.event.date }}</p>
      <p>{{ location.comment }}</p>
    </div>
    <div class="gd-container" fxLayout="column" fxFlex="1 1 33%" fxFlex.sm="1 1 50%">
      <img class="image" [defaultImage]="'assets/gd_placeholder.png'" [lazyLoad]="location.thumbnail" fxFlex="1 1 auto">
    </div>
    <div class="gd-container" fxLayout="column" fxFlex="1 1 33%" fxFlex.sm="1 1 100%">
      <gd-map fxFlex="1 1 auto" [style.height]="'100%'" zoom="15"
        latitude="{{ location.geoloc.lat }}" longitude="{{ location.geoloc.long }}"></gd-map>
    </div>
  </div>

  
  <div *ngIf="videos">
    <div class="gd-container" fxLayout="column" fxFlex="50">
      <gd-youtube currentVideoId="{{currentVideoId}}" [videos]="videos" width="100%"></gd-youtube>
    </div>
  </div>

  
  <p class="gd-section">All shows in {{ location.name }}:</p>
  
  <gd-shows [eventIds]="location.eventIds" [onOptions]="openOptionsDialog.bind(this)"></gd-shows>
  <gd-comments [userName]="currentUser.userName" [currentUserId]="currentUser.userId" [title]="location.name+', '+location.state"></gd-comments>
  <br>
  <gd-sharebuttons></gd-sharebuttons>
</div>
<p [style.margin-bottom]="'75px'"></p>