

<div class="scale">
    <!--button mat-button class="close-icon" [mat-dialog-close]="true">
        <mat-icon>close</mat-icon>
    </button-->
    <iframe src="https://www.buymeacoffee.com/widget/page/gratefullive?color=235F7FFF" 
        style="position: relative; height: 100%; width: 100%; top: 0;  bottom: 0; left: 0; border: 0;"></iframe>
    <button class="button">
        <a class="donate-with-crypto"
            href="https://commerce.coinbase.com/checkout/823db1de-4678-4e63-abde-d304972a6ec6" target="_blank">
            Donate with crypto
        </a>
        <script src="https://commerce.coinbase.com/v1/checkout.js?version=201807">
        </script>
    </button>
    <div>
        <img src="/assets/chia_logo.svg" class="chia-img">
        <div class="chia-address" cdkCopyToClipboard="xch1nh0s9xp7666pracqs794st30s9l0kpg69cc9r9fzlanzjvg7e37stfwdws">
            xch1nh0s9xp7666pracqs794st30s9l0kpg69cc9r9fzlanzjvg7e37stfwdws
        </div>
    </div>
</div>



