<gd-header *ngIf="!song" style="position: fixed; width: 100%" title=" " userId='{{ currentUser.userId }}'></gd-header>
<div *ngIf="!song && spinTime" class="wait">
  <div class="loader"></div>
</div>
<div *ngIf="!song && spinTime" class="wait"><img src="/assets/bearsanimated.webp" width="160"></div>

<div *ngIf="song" fxLayout="column">
  <gd-header *ngIf="song" title="{{ song.name }}"
    subtitle="{{ subtitle }}" userId='{{ currentUser.userId }}'></gd-header>
  <div fxLayout="column" class="gd-container">
    
    <div fxLayout="column" fxFlex class="gd-container">
      <ng-container *ngIf="song.composedBy && song.composedBy.length">
        Composed by:
        <div *ngFor="let a of song.composedBy"
          [routerLink]="['/artist', a.id]">{{a.name}}</div><br/>
      </ng-container>
      <ng-container *ngIf="song.lyricsBy && song.lyricsBy.length">
        Lyrics by:
        <div *ngFor="let a of song.lyricsBy"
          [routerLink]="['/artist', a.id]">{{a.name}}</div><br/>
      </ng-container>
      <ng-container *ngIf="firstPlayed">
        First played on: {{ firstPlayed }}<br/></ng-container>
      <ng-container *ngIf="lastPlayed">
        Last played on: {{ lastPlayed }}<br/></ng-container>
      <ng-container *ngIf="timesPlayed">
        Number of performances: {{ timesPlayed }}<br/></ng-container>
      <ng-container *ngIf="totalRecordings">
        Total number of recordings: {{ totalRecordings }}<br/></ng-container>
    </div>
    
    <p class="gd-container">All performances of {{song.name}}:</p>
    
    <gd-shows [eventIds]="song.eventIds" [onOptions]="openOptionsDialog.bind(this)"></gd-shows>
  </div>

  <div *ngIf="videos">
    <div class="gd-container" fxLayout="column" fxFlex="50">
      <gd-youtube currentVideoId="{{currentVideoId}}" [videos]="videos" width="100%"></gd-youtube>
    </div>
  </div>



  <gd-comments [userName]="currentUser.userName" [currentUserId]="currentUser.userId" [title]="song.name"></gd-comments>
  <br>
  <gd-sharebuttons></gd-sharebuttons>
</div>
<p [style.margin-bottom]="'75px'"></p>