<div fxLayout="column" class="gd-container">
  <div *ngFor="let e of events | paginate: { itemsPerPage: 10, currentPage: p }" fxLayout="row" fxLayoutAlign=" center">
    <img fxLayout="column" class="image field" fxFlex="1 1 15%"
      [style.width]="'80px'" [style.height]="'60px'"
      [defaultImage]="'assets/gd_placeholder.png'"
      [lazyLoad]="e.artifacts[0] ? e.artifacts[0].thumbnail || e.artifacts[0].image: null"
      [style.object-fit]="'cover'"
      [routerLink]="['/show', e.id]" matTooltip="go to show" class='link'>
      <div fxLayout="column" fxFlex="1 1 10%" fxLayoutAlign="center"
      [ngStyle.xs]="{'font-size': '80%'}" class="field"
        [routerLink]="['/show', e.id]" matTooltip="go to show" class='link' style="margin-left:12px;">
        {{ e.date }}
      </div>
      <div fxLayout="column" fxFlex="1 1 25%" fxLayoutAlign="center"
      [ngStyle.xs]="{'font-size': '80%'}" class="field"
        [routerLink]="['/show', e.id]" matTooltip="go to venue" class='link'>
        {{ e.venue }} 
      </div>
      <span class="field"
        [routerLink]="['/show', e.id]" matTooltip="go to location" class='link'>
      {{ e.location }}
    </span>
    <button *ngIf="onOptions" mat-icon-button fxLayout="column" class="field" fxFlex="1 1 10%"
      (click)="onOptions(e)" matTooltip="show options"><mat-icon>more_horiz</mat-icon>
    </button>


    <!--
    <div fxLayout="column" fxFlex="1 1 25%" fxLayoutAlign="center"
        [ngStyle.xs]="{'font-size': '80%'}" class="field"
        [routerLink]="['/show', e.id]">
      {{ e.date }}
    </div>
    <div fxLayout="column" fxFlex="1 1 30%" fxLayoutAlign="center"
        [ngStyle.xs]="{'font-size': '80%'}" class="field"
        [routerLink]="['/show', e.id]">
      {{ e.venue }}
    </div>
    <div fxLayout="column" fxFlex="1 1 20%" fxLayoutAlign="center"
        [ngStyle.xs]="{'font-size': '80%'}" class="field"
        [routerLink]="['/show', e.id]">
      {{ e.location }}
    </div>
    <button mat-icon-button fxLayout="column" class="field" fxFlex="1 1 10%"
      (click)="onOptions(e)"><mat-icon>more_horiz</mat-icon>
    </button>
    -->
  </div>
  <div *ngIf="pages > 1">
    <pagination-controls (pageChange)="p = $event"></pagination-controls>  

    <input type="text" [formControl]="pageNum" (keypress)="keyPress($event)" (keyup.enter)="goToPage(pageNum)" style="width: 25px;">
    <button (click)="goToPage(pageNum)" style="width: 100px;">go to page</button>
  </div>
</div>
<p [style.margin-bottom]="'75px'"></p>