
<div>
    Send feedback/suggestions/bug report
</div>

<textarea id="textbox" 
    [(ngModel)]="feedback" 
    maxlength="{{inputMaxLength}}"
    (keyup)="onKeyUp(feedback)">
</textarea>
<div class="chars">
    {{typedLength + " / " + inputMaxLength + " characters"}}
</div>

<button mat_flat-button [disabled]="feedback == ''" mat-flat-button style="color: rgb(194, 192, 192)" (click)="onClick()">Send</button>
<button mat_flat-button [mat-dialog-close]="true" mat-flat-button style="color: rgb(194, 192, 192)">Cancel</button>
