

<gd-header style="position: fixed; width: 100%" title="Index" userId='{{ currentUser.userId }}'></gd-header>

<p [style.margin-bottom]="'50px'"></p>
    <div class="topmargin">
    <button mat-flat-button fxFlex [color]="selected=='shows' ? 'warn' : 'primary'" (click)="onSelectButton('shows')">Shows</button>
    <button mat-flat-button fxFlex [color]="selected=='venues' ? 'warn' : 'primary'" (click)="onSelectButton('venues')">Venues</button>
    <button mat-flat-button fxFlex [color]="selected=='locations' ? 'warn' : 'primary'" (click)="onSelectButton('locations')">Locations</button>
    <button mat-flat-button fxFlex [color]="selected=='songs' ? 'warn' : 'primary'" (click)="onSelectButton('songs')">Songs</button>
    </div>

    
<div *ngIf="!index && spinTime" class="wait">
  <div class="loader"></div>
</div>
<div *ngIf="!index && spinTime" class="wait && spinTime"><img src="/assets/bearsanimated.webp" width="160"></div>

 <div *ngIf="index">
    <div *ngIf="selected=='venues'">
        <table>
            <tr>
                <th (click)="onClickTitle('venues', 'venueName')" style="cursor:pointer">Venue</th>
                <th (click)="onClickTitle('venues', 'locationName')" style="cursor:pointer">Location</th>
                <th (click)="onClickTitle('venues', 'numEvents')" style="cursor:pointer">Shows</th>
                <th (click)="onClickTitle('venues', 'numPhotos')" style="cursor:pointer">Photos</th>
                <th (click)="onClickTitle('venues', 'numArtefacts')" style="cursor:pointer">Artifacts</th>
            </tr>
            <tr *ngFor="let s of venues">
                <td [routerLink]="['/venue', s.venueId]" style="cursor:pointer">{{ s.venueName }}</td>
                <td [routerLink]="['/venue', s.venueId]" style="cursor:pointer">{{ s.locationName }}</td>
                <td [routerLink]="['/venue', s.venueId]" style="cursor:pointer">{{ s.numEvents }}</td>
                <td [routerLink]="['/venue', s.venueId]" style="cursor:pointer">{{ s.numPhotos }}</td>
                <td [routerLink]="['/venue', s.venueId]" style="cursor:pointer">{{ s.numArtefacts }}</td>
            </tr>
        </table>
    </div>



    <div *ngIf="selected=='locations'">
        <table>
            <tr>
                <th (click)="onClickTitle('locations', 'locationName')" style="cursor:pointer">Location</th>
                <th (click)="onClickTitle('locations', 'country')" style="cursor:pointer">State/Country</th>
                <th (click)="onClickTitle('locations', 'numEvents')" style="cursor:pointer">Shows</th>
                <th (click)="onClickTitle('locations', 'numPhotos')" style="cursor:pointer">Photos</th>
                <th (click)="onClickTitle('locations', 'numArtefacts')" style="cursor:pointer">Artifacts</th>
            </tr>
            <tr *ngFor="let s of locations">
                <td [routerLink]="['/location', s.locationId]" style="cursor:pointer">{{ s.locationName }}</td>
                <td [routerLink]="['/location', s.locationId]" style="cursor:pointer">{{ s.country }}</td>
                <td [routerLink]="['/location', s.locationId]" style="cursor:pointer">{{ s.numEvents }}</td>
                <td [routerLink]="['/location', s.locationId]" style="cursor:pointer">{{ s.numPhotos }}</td>
                <td [routerLink]="['/location', s.locationId]" style="cursor:pointer">{{ s.numArtefacts }}</td>
            </tr>
        </table>
    </div>


    <div *ngIf="selected=='songs'">
        <table>
            <tr>
                <th (click)="onClickTitle('songs', 'songName')" style="cursor:pointer">Song</th>
                <th (click)="onClickTitle('songs', 'numEvents')" style="cursor:pointer">Performances</th>
            </tr>
            <tr *ngFor="let s of songs">
                <td [routerLink]="['/song', s.songId]" style="cursor:pointer">{{ s.songName }}</td>
                <td [routerLink]="['/song', s.songId]" style="cursor:pointer">{{ s.numEvents }}</td>
            </tr>
        </table>
    </div>


    <div *ngIf="selected=='shows'">
        <table>
            <tr>
                <th (click)="onClickTitle('shows', 'date')" style="cursor:pointer">Date</th>
                <th (click)="onClickTitle('shows', 'venueName')" style="cursor:pointer">Venue</th>
                <th (click)="onClickTitle('shows', 'locationName')" style="cursor:pointer">Location</th>
                <th (click)="onClickTitle('shows', 'photos')" style="cursor:pointer">Photos</th>
                <th (click)="onClickTitle('shows', 'artefacts')" style="cursor:pointer">Artifacts</th>
            </tr>
            <tr *ngFor="let s of shows">
                <td [routerLink]="['/show', s.showId]" style="cursor:pointer">{{ s.date }}</td>
                <td [routerLink]="['/show', s.showId]" style="cursor:pointer">{{ s.venueName }}</td>
                <td [routerLink]="['/show', s.showId]" style="cursor:pointer">{{ s.locationName }}</td>
                <td [routerLink]="['/show', s.showId]" style="cursor:pointer">{{ s.photos }}</td>
                <td [routerLink]="['/show', s.showId]" style="cursor:pointer">{{ s.artefacts }}</td>
            </tr>
        </table>
    </div>
</div>

<p [style.margin-bottom]="'150px'"></p>