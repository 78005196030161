
<div  style="height: auto; width: auto;">
  <gd-chatterbox *ngIf='heading != ""'
      [heading]=heading
      [headerImage]=headerImage
      [allComments]=allComments
      [inputMaxLength]=1000
      [currentUserId]=currentUserId
      [showErrorDiv]=showErrorDiv
      [errorMsg]=errorMsg
      [placeholderText]=placeholderText
      (onKeyupEnter)=sendMessage($event)
      (emitReport)=onReportComment($event)
      (emitDelete)=onDeleteComment($event)
      (emitRefresh)=getComments()
      [socketIo] = socketIo
  >
  </gd-chatterbox>

  <gd-chatterbox *ngIf='heading == ""'
      heading=""
      [headerImage]=headerImage
      [allComments]=allComments
      [inputMaxLength]=0
      [showErrorDiv]=showErrorDiv
      [errorMsg]=errorMsg
      placeholderText="please log in to comment"
  >
  </gd-chatterbox>
</div>



