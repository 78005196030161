export const AUTH0DOMAIN = 'gdconcertexplorer.eu.auth0.com';
export const AUTH0CLIENTID = 'MgqjkApiGjSzMcFwjzUiyoNJK6z8iWEb';
export const EMAILADDRESS = 'gdconcertexplorer@gmail.com';
export const TRACKINGID = 'UA-165369751-1';
//export const GOOGLETAGID = 'GTM-TT7TKCZ';
export const TRACKING = false;
//export const API_URL = 'http://localhost:8060/';
//export const API_URL = 'http://c4dm.eecs.qmul.ac.uk/dead/';
//export const API_URL = 'http://25.86.166.144:8060/';
//export const API_URL = 'https://grateful-dead-api.herokuapp.com/';
//export const FRONTEND_URL = 'http://localhost:4200/';
export const FRONTEND_URL = 'https://grateful-dead-live.github.io/';
//export const API_URL = 'https://cors-anywhere.herokuapp.com/http://82.5.176.6:8080/'
//export const API_URL = 'https://cors-anywhere.herokuapp.com/http://138.37.95.164/api/'
//export const API_URL = 'https://cors-anywhere.herokuapp.com/http://c4dm-xenserv-virt6.eecs.qmul.ac.uk/api/'
//export const API_URL = 'https://c4dm.eecs.qmul.ac.uk/dead/'
//export const API_URL = 'https://cors-anywhere.herokuapp.com/http://dead.ddnsfree.com:8080/'
 
/*
export const API = 'localhost:8060/'
export const API_URL = 'http://' + API;
export const WSS_URL = 'wss://dead.ddnsfree.com:8060/';
*/


export const API = 'dead.ddnsfree.com:8060/'
//export const API = 'dead.ddnsfree.com/'
export const API_URL = 'https://' + API;
export const WSS_URL = 'wss://' + API;


export const DEBUG = false;
export const SOCKETIO = true;
 
export const DOMAIN = 'grateful-live.org';

export const AUTHGUARD = false;