<!--div *ngIf="recording && event" fxLayout="column">
  <gd-header *ngIf="recording && event" title='{{ recording.etreeId }}'
    subtitle='{{ event.location }}'>
  </gd-header>

  <div *ngIf="recording" class="gd-container" fxLayout="column" class="topmargin" >
    <div fxLayout="column" fxFlex class="gd-container">
      <ng-container *ngIf="event">
        Recorded at: {{ event.venue }}, {{ event.location }}, {{ event.state }}, {{ event.date }}<br></ng-container>
      <ng-container *ngIf="recording.info.lineage">
        Lineage: {{ recording.info.lineage }}<br></ng-container>
      <ng-container *ngIf="recording.info.source">
        Source: {{ recording.info.source }}<br></ng-container>
      <ng-container *ngIf="recording.info.notes">
        Notes: {{ recording.info.notes }}<br/></ng-container>
      <ng-container *ngIf="recording.info.keywords">
        Notes: {{ recording.info.keywords }}<br/></ng-container>
      <br>
      <ng-container *ngIf="recording.tracks">
        Tracks:
        <div *ngFor="let t of recording.tracks">
          <span [routerLink]="['/song', t.id]"> {{ t.track }}: {{ t.title }}</span>
          <button mat-icon-button (click)="openTrackOptionsDialog(t)">
            <mat-icon>more_horiz</mat-icon>
          </button>
        </div><br/>
      </ng-container-->


<gd-header *ngIf="!recordinginfo" style="position: fixed; width: 100%" title=" " userId='{{ currentUser.userId }}'></gd-header>
<div *ngIf="!recordinginfo && spinTime" class="wait">
  <div class="loader"></div>
</div>
<div *ngIf="!recordinginfo && spinTime" class="wait"><img src="/assets/bearsanimated.webp" width="160"></div>


<div *ngIf="recordinginfo" fxLayout="column">
  <gd-header title='{{ recordinginfo.etree_id }}'
    subtitle=' – {{ recordinginfo.location_name }}' userId='{{ currentUser.userId }}'>
  </gd-header>

      <div class="gd-container" fxLayout="column" class="topmargin" >
        <div fxLayout="column" fxFlex class="gd-container">
          <ng-container>
            <i>Recorded at:</i> 
            <span class="link" [routerLink]="['/venue', recordinginfo.venue_id]" matTooltip="go to venue">
              {{ recordinginfo.venue_name }}, </span>
            <span class="link" [routerLink]="['/location', recordinginfo.location_id]" matTooltip="go to location">
              {{ recordinginfo.location_name }}, </span>
            <span class="link" [routerLink]="['/show', recordinginfo.show_id]" matTooltip="go to recording">
              {{ recordinginfo.date }} </span>
          </ng-container><div><br></div>

          <ng-container *ngIf="recordinginfo.lineage">
            <i>Lineage:</i> {{ recordinginfo.lineage }}<div><br></div></ng-container>
          <ng-container *ngIf="recordinginfo.source">
            <i>Source:</i> {{ recordinginfo.source }}<div><br></div></ng-container>
          <ng-container *ngIf="recordinginfo.notes">
            <i>Notes:</i> {{ recordinginfo.notes }}<div><br></div></ng-container>
          <ng-container *ngIf="recordinginfo.subject">
            <i>Subject:</i> {{ _array.isArray(recordinginfo.subject) ? recordinginfo.subject.join(', ') : recordinginfo.subject }}</ng-container><div><br></div>
          <ng-container *ngIf="tracklist">
            Tracks:
            <div *ngFor="let t of tracklist">
              <span> {{ t.track }}: {{ t.title }}</span>
              <button mat-icon-button (click)="openTrackOptionsDialog(t)" matTooltip="open track options menu">
                <mat-icon>more_horiz</mat-icon>
              </button>
            </div>
            <span class="link" (click)="addAllToPlaylist()">add all to playlist</span>
          </ng-container><div><br></div>
          

    <a href="{{ 'https://archive.org/details/' + recordinginfo.etree_id }}" target="_blank">
      <img src="https://archive.org/images/glogo.png" [style.object-fit]="'contain'" matTooltip="go to recording at the Internet Archive"></a>
     
    </div>
  </div>
  <gd-comments [userName]="currentUser.userName" [currentUserId]="currentUser.userId" [title]="recordinginfo.etree_id+', '+recordinginfo.location_name"></gd-comments>
  <br>
  <gd-sharebuttons></gd-sharebuttons>
</div>
<p [style.margin-bottom]="'75px'"></p>