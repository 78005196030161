<gd-header *ngIf="!event" style="position: fixed; width: 100%" title=" " userId='{{ currentUser.userId }}'></gd-header>
<div *ngIf="!event && spinTime" class="wait">
  <div class="loader"></div>
</div>
<div *ngIf="!event && spinTime" class="wait"><img src="/assets/bearsanimated.webp" width="160"></div>

<div *ngIf="event" fxLayout="column">
  <gd-header style="position: fixed; width: 100%" *ngIf="event" title='{{event.location.name}}, {{event.location.state}}' subtitle='{{formatDate}}'
    imageUrl="{{eventImage}}" userId='{{ currentUser.userId }}'>
    <gd-weather [weather]="event.weather"></gd-weather>
  </gd-header>
  <div class="gd-container" fxLayout="row wrap" fxLayout.xs="column" class="topmargin" >
    
    <div class="gd-container link" fxLayout="column" fxFlex="50" [routerLink]="['/location', event.location.id]" matTooltip="go to location">
      <h2 class="titlef">Location: <span class="normweight">{{ event.location.name }}</span></h2>
      <img class="image" *ngIf="event.location.thumbnail" [lazyLoad]="event.location.thumbnail" fxFlex="1 1 auto" 
        [style.object-fit]="'contain'">
    </div>
    
    <div class="gd-container link" fxLayout="column" fxFlex="50" [routerLink]="['/venue', event.venue.id]" matTooltip="go to venue">
      <h2 class="titlef">Venue: <span class="normweight">{{ event.venue.name }}</span></h2>
      <img class="image" *ngIf="event.venue.thumbnail" [lazyLoad]="event.venue.thumbnail" fxFlex="1 1 auto" 
        [style.object-fit]="'contain'">
    </div>
    
    <div class="gd-container" fxLayout="column" fxFlex="50">
        <h2 class="titlef">Setlist </h2>
      <div fxLayout="column" *ngFor="let set of event.setlist">
        <h3>{{ set.name }}</h3>
        <!-- <div *ngFor="let song of set.songs" fxLayout="row" fxLayoutAlign="space-between center"> -->
        <div *ngFor="let song of set.songs, let i = index" fxLayout="row">
          <span [routerLink]="['/song', song.id]" matTooltip="go to song" class="link">{{( "0"+(i+1)).slice(-2) }} {{ song.name }}</span>
          <button mat-icon-button (click)="openSongOptionsDialog(song, set.name, i+1)" matTooltip="song options">
            <mat-icon>more_horiz</mat-icon>
          </button>
        </div>
      </div>
    </div>
    
    <div class="gd-container" fxLayout="column" fxFlex="50">
      <h2 class="titlef">Lineup</h2>
        <div *ngFor="let p of event.performers" [routerLink]="['/artist', p.id]" matTooltip="go to artist" class="link"> 
          <img class="image" [lazyLoad]="p.thumbnail"
          [style.width]="'70px'" [style.height]="'70px'"> 
          <span>&nbsp;&nbsp;&nbsp;&nbsp;{{ p.name }} ({{ p.instruments.join(', ') }})</span>
        </div>
 
    <!--div fxLayout="column" fxFlex="1 1 auto">
      <iframe [src]="recordingUrls[0]" [style.min-width]="'350px'" [style.height]="'100%'" frameborder="0"></iframe>
    </div-->
    
  </div>
  
  <div class="gd-container" fxLayout="column" fxFlex="50">
    <h2 class="titlef">News of the week</h2>


    <!--div *ngFor="let n of event.news" matTooltip="open article" class="link">
      <a [href]="n.url" target="_blank" [style.text-decoration]="'none'">
        {{ n.date+": "+n.title+" ("+n.source+")" }}
      </a>
    </div-->
    <ng-ticker *ngIf="event.news.length!=0" [stopOnHover]="true" [speed]="tickerTime+'s'" class="ticker">
      <span *ngFor="let n of event.news" matTooltip="open article" class="link">
        <img class="news-logo" *ngIf="n.source=='The Guardian'" src="/assets/guardian_logo.png" height="11px">
        <img class="news-logo" *ngIf="n.source=='The New York Times'" src="/assets/nytimes_logo.png" height="11px">
        <span class="ticker-date">{{n.date}}</span>
        <a [href]="n.url" target="_blank" class="ticker-text">
          {{ n.title }} 
        </a>
        <span style="padding-right:30px;"></span>
      </span>
  </ng-ticker>
  </div>
  
  <div class="gd-container" fxLayout="column" fxFlex="50">
    <h2 class="titlef">Available recordings</h2>
    <div *ngFor="let r of event.recordings">
      <span [routerLink]="['/recording', r.id]" matTooltip="go to recording" class="link">{{ r.etreeId }}</span>
      <button mat-icon-button (click)="openRecordingOptionsDialog(r)" matTooltip="recording options">
        <mat-icon>more_horiz</mat-icon>
      </button>
    </div>
  </div>
  

    <!-- 
  <h2 *ngIf="photos.length" class="gd-section"><span class="titlef">Photos from the show</span></h2>
  
  <div *ngIf="photos.length" class="gd-container" fxLayout="row wrap" fxLayout.xs="column">
    <div *ngFor="let s of photos" class="gd-container" fxLayout="column" fxFlex="33" fxFlex.xs="100">
      <img style="max-width: 200px;" class="image" [lazyLoad]="s" [style.object-fit]="'contain'">
    </div>
  </div>
-->

<div *ngIf="photos.length>0" style="width: 100%;">
  <div class="gd-container" fxLayout="column" fxFlex="100">
    <h2 class="gd-section"><span class="titlef">Photos</span></h2>
    <drag-scroll>
      <div drag-scroll-item *ngFor="let item of photos; let i = index;" style="margin-top: 30px;">
        <div *ngVar="tilt(i, 'photo') as degVar" [ngStyle]="{'transform': 'rotate('+degVar+'deg)', '-webkit-transform': 'rotate('+degVar+'deg)'}" >
          <img class="scroll-image" src="{{ item.thumbnail }}" (click)="onClickPhoto(item);" class="carousel-photo" style="cursor:pointer;">
        </div>
      </div>
    </drag-scroll>
  </div>
</div>


<div *ngIf="artifacts.length>0" style="width: 100%;">
  <div class="gd-container" fxLayout="column" fxFlex="100">
    <h2 class="gd-section"><span class="titlef">Other Artifacts</span></h2>
    <drag-scroll>
      <div drag-scroll-item *ngFor="let item of artifacts; let i = index;" style="margin-top: 40px;">
        <div *ngVar="tilt(i, 'artifact') as degVar" [ngStyle]="{'transform': 'rotate('+degVar+'deg)', '-webkit-transform': 'rotate('+degVar+'deg)'}">
        <img class="scroll-image" src="{{ item.thumbnail }}" (click)="onClickArtifact(item);" style="cursor:pointer;">
      </div>
      </div>
    </drag-scroll>
  </div>
</div>


  </div>
  <gd-comments [userName]="currentUser.userName" [currentUserId]="currentUser.userId" [title]="event.location.name+', '+event.location.state+' – '+event.date"></gd-comments>
  <br>
  <gd-sharebuttons></gd-sharebuttons>
</div>
<p [style.margin-bottom]="'75px'"></p>